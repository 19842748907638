import { Radio, RadioGroup } from '@headlessui/react';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';

import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import { Type } from '../lib/order';
import { Provider } from '../types/src';

export type OrderType = {
  type: Type;
  name: string;
  description: string;
};

export const DELIVERY: OrderType = {
  type: Type.DELIVERY,
  name: 'deliverytypes.delivery.title',
  description: 'deliverytypes.delivery.description',
};

export const PICKUP: OrderType = {
  type: Type.PICKUP,
  name: 'deliverytypes.pickup.title',
  description: 'deliverytypes.pickup.description',
};

export default function OrderType({ provider }: { provider: Provider }) {
  const router = useRouter();

  const { type } = router.query;

  let types: OrderType[] = [];

  useEffect(() => {
    const queryType = router.query.type;
    if (types.length && !type) {
      const index = types.findIndex((t) => t.type === queryType);
      if (queryType && index > -1) {
        setSelectedType(types[index].type);
      } else if (!type) {
        setSelectedType(types[0].type);
      }
    }
  }, [types]);

  if (provider.deliveryAvailable) {
    types.push(DELIVERY);
  }
  if (provider.pickupAvailable) {
    types.push(PICKUP);
  }

  function setSelectedType(selectedTypeValue: string) {
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, type: selectedTypeValue },
      },
      undefined,
      { shallow: true },
    );
  }

  const { t } = useTranslation('provider');

  return (
    <div className="w-full py-4">
      <div className="w-full max-w-md">
        <RadioGroup value={type} onChange={setSelectedType}>
          <div className="space-y-2">
            {types.map((ty) => (
              <Radio
                key={ty.name}
                value={ty.type}
                className={({ checked }) =>
                  `${checked ? 'bg-primary text-on-primary' : 'bg-surface-container-high text-on-surface'}
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
                }
              >
                {({ checked }) => (
                  <>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <p className={`font-medium`}>{t(ty.name)}</p>
                          <span className={`inline`}>
                            <span>{t(ty.description)}</span>
                          </span>
                        </div>
                      </div>
                      {checked && (
                        <div className="flex-shrink-0 text-on-primary">
                          <CheckCircleIcon className="w-6 h-6 text-on-primary" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Radio>
            ))}

            {types.length === 0 ? <p className="text-error">{t('noordertype')}</p> : null}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}
