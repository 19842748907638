import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { COUNTRIES, Country } from '../types/src';

export function findCountryOfCurrentPhone(phone): Country | null {
  try {
    const phoneNumber = parsePhoneNumber(phone);
    if (phoneNumber && phoneNumber.country) {
      const country = phoneNumber.country;
      const found = COUNTRIES.find((c) => c.isoCode === country);
      return found;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export function parseNumber(phone: string, country: Country): string {
  try {
    const phoneNumber = parsePhoneNumber(phone, (country?.isoCode as CountryCode) || 'IT');
    if (phoneNumber) {
      const formatted = phoneNumber.formatInternational();

      return formatted;
    }
  } catch (error) {
    return '';
  }
}
