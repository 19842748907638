import { RadioGroup } from '@headlessui/react';
import { mdiCircleSlice3, mdiCircleSlice4, mdiCircleSlice8 } from '@mdi/js';
import Icon from '@mdi/react';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';

const ReactTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false,
});

export default function SliceNumber({
  number,
  min,
  max,
  changedNumber,
}: {
  number: number;
  min: number;
  max: number;
  changedNumber: (number) => void;
}) {
  const { t } = useTranslation('provider');

  const slices: { name: string; number: number }[] = [
    {
      name: min == 0 ? 'slices.hole' : 'slices.name',
      number: min,
    },
    ...Array.from(Array(max - min).keys()).map((_, index) => {
      return {
        name: index + 1 + min == 1 ? 'slices.hole' : 'slices.name',
        number: index + 1 + min,
      };
    }),
  ];

  return (
    <>
      <p className="text-center mb-4 text-sm"> {t('slices.count_description')} </p>
      <div className="w-fit bg-primary-container p-2 rounded-xl mx-auto">
        <RadioGroup value={number} onChange={changedNumber}>
          <RadioGroup.Label className="sr-only">Slice number</RadioGroup.Label>
          <div className="flex flex-row space-x-2">
            {slices.map((slice) => {
              const text = slice.number ? (slice.number == 1 ? t('slices.hole') : `${slice.number} ${t(slice.name)}`) : null;
              return (
                <RadioGroup.Option
                  data-tip={text}
                  key={slice.number}
                  value={slice.number}
                  className={({ checked }) =>
                    `${checked ? 'bg-primary text-on-primary' : 'bg-surface text-on-surface'}
                relative rounded-lg shadow-md p-2 cursor-pointer flex focus:outline-none`
                  }
                >
                  {({ checked }) => (
                    <>
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label as="div" className={`font-medium`}>
                              <div className="flex flex-row items-center flex-wrap">
                                {slice.number === 1 && <Icon size={1} path={mdiCircleSlice8} />}
                                {slice.number === 2 && <Icon size={1} path={mdiCircleSlice4} />}
                                {slice.number >= 3 && <Icon size={1} path={mdiCircleSlice3} />}
                              </div>
                            </RadioGroup.Label>
                            {slice.number === number && (
                              <RadioGroup.Description
                                as="span"
                                className={`inline ${checked ? 'text-bringmos-100' : 'text-gray-500'}`}
                              ></RadioGroup.Description>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              );
            })}
          </div>
        </RadioGroup>
      </div>
      <ReactTooltip effect="solid" />
    </>
  );
}
