import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import { ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import extrasStore from '../lib/extras';
import { getCategoryAvatarFromCode, getCategoryFromCode } from '../lib/helper';
import ingredientsStore from '../lib/ingredients';
import orderStore from '../lib/order';
import { Extra, MenuItem, MenuItemOrder, Provider, SliceData } from '../types/src';
import { jsonToTimestamp } from '../utils/date';
import { isItemLimited, nextAvailable } from '../utils/menuItem';
import Allergics from './Allergics';
import { Button } from './Button';
import Combo from './Combo';
import Extras from './Extras';
import IngredientSelect, { IngredientReturnType } from './IngredientSelect';
import PizzaConfigurator from './PizzaConfigurator';
import { TextInput } from './TextInput';

const ReactTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false,
});

export enum ExpandedView {
  INGREDIENTS,
  PIZZA_CONFIGURATOR,
  COMBO,
  ADDITIONAL_INFO,
}

type Props = {
  provider: Provider;
  menuItem: MenuItem;
  loadedMenuItems: MenuItem[];
  locale: string;
  toggle: any;
  expanded: boolean;
  disabled: boolean;
  ingredientsForSlicesDisabled: boolean;
  menuOnly: boolean;
};

function MenuItemComponent(props: Props) {
  const [count, setCount] = useState(1);
  const [extras, setExtras] = useState([]);
  const [ingredients, setIngredients] = useState<IngredientReturnType>();
  const [sliceData, setSliceData] = useState<Array<SliceData>>();
  const [comboData, setComboData] = useState<Partial<MenuItemOrder>[]>([]);
  const [sliceCount, setSliceCount] = useState<number>(0);

  const loadedIngredients = ingredientsStore((state) => (state as any).ingredients);
  const loadedExtras = extrasStore((state) => (state as any).extras);

  useEffect(() => {
    setCount(1);
    setExtras([]);
    setIngredients(null);
    setSliceData([]);
    setComboData([]);
    setSliceCount(0);
    if (props.expanded) {
      executeScroll();
    }
  }, [props.expanded]);

  function decrementCount($event) {
    const newCount = count > 1 ? count - 1 : 1;
    setCount(newCount);
  }

  function incrementCount($event) {
    const newCount = count + 1;
    setCount(newCount);
  }

  function clickContainer($event: any) {
    if ($event.target.tagName !== 'BUTTON' && !props.disabled && !itemLimited) {
      $event.preventDefault();
      props.toggle(props.menuItem.uid);
    }
  }

  function setExtrasToMenuItem(extras: Extra[]) {
    setExtras(extras);
  }

  function setIngredientsToMenuItem(ings: IngredientReturnType) {
    setIngredients(ings);
  }

  const { t } = useTranslation('provider');
  const addMenuItem = orderStore((state: any) => state.addMenuItem);

  const isCustomPizza = props.provider.customItemsAvailable && props.menuItem.category_code === 'pizza';
  const isSlicesPizza =
    props.provider.customItemsAvailable && props.menuItem.category_code === 'pizza' && props.menuItem.slices?.available;
  const isMammut = props.menuItem.category_code === 'pizzamammut' && props.menuItem.slices?.available;

  const isCombo = !!props.menuItem.combo_config;
  const showView = isCombo
    ? ExpandedView.COMBO
    : isSlicesPizza || isMammut
      ? ExpandedView.PIZZA_CONFIGURATOR
      : isCustomPizza
        ? ExpandedView.INGREDIENTS
        : ExpandedView.ADDITIONAL_INFO;

  const ingPrice =
    ingredients?.added_ingredients && Object.keys(ingredients.added_ingredients).length
      ? Object.keys(ingredients.added_ingredients)
          .map((id) => loadedIngredients.find((ing) => ing.uid === id)?.price ?? 0)
          .reduce((acc, price) => acc + price, 0)
      : 0;

  function addMenuItemToOrder() {
    // always equals to the first attribute
    const sumWithIngredients = Number(props.menuItem.price) + Number(ingPrice);
    let newItem: Partial<MenuItemOrder> = {
      ...props.menuItem,
      extraItems: extras,
      initial_ingredients: props.menuItem.multi_ingredients
        ? props.menuItem.multi_ingredients
        : {
            de: '',
            it: '',
            en: '',
          },
      initialIngredientIds: props.menuItem.ingredientIds ? props.menuItem.ingredientIds : [],
      price: sumWithIngredients,
      extras: extrasValue ? extrasValue : '',
      date:
        props.menuItem.date && typeof props.menuItem.date == 'object'
          ? jsonToTimestamp(props.menuItem.date)
          : props.menuItem.date,
      ...ingredients,
    };

    if (sliceData) {
      newItem.slice_data = sliceData;
    }

    if (comboData) {
      newItem.combo_data = { selection: comboData };
    }

    if (
      ingredients &&
      ((ingredients.added_ingredients &&
        typeof ingredients.added_ingredients === 'object' &&
        Object.keys(ingredients.added_ingredients).length) ||
        (ingredients.removed_ingredients &&
          typeof ingredients.removed_ingredients === 'object' &&
          Object.keys(ingredients.removed_ingredients).length))
    ) {
      newItem.custom = true;
      let newNames = {};
      Object.keys(newItem.names).forEach((l) => (newNames[l] = `CUSTOM ${newItem.names[l]}`));
      newItem.names = newNames;
    }

    toast.success(t('provider:addedtocart'));
    addMenuItem(newItem, count, props.locale);
  }

  const itemLimited = isItemLimited(props.menuItem);

  const limitedTimes = nextAvailable(props.menuItem, t);

  const iconButton =
    'transition-all outline-none flex items-center justify-center h-8 w-8 rounded-full text-primary dark:text-primary hover:bg-opacity-10 dark:hover:bg-opacity-10 hover:bg-gray-800 dark:hover:bg-gray-400 focus:outline-none disabled:text-gray-300 dark:disabled:text-gray-500 disabled:bg-transparent dark:disabled:bg-transparent disabled:cursor-default dark:disabled:cursor-default';
  const selectedIngredients =
    loadedIngredients && loadedIngredients.length && props.menuItem.ingredientIds
      ? loadedIngredients.filter((ings) => props.menuItem.ingredientIds.includes(ings.uid))
      : [];

  const [extrasValue, setExtrasValue] = useState<string>('');

  function updateExtrasValue(event: any) {
    setExtrasValue(event.target?.value ?? '');
  }

  const myRef = useRef(null);

  const executeScroll = () => {
    if (window) {
      const yOffset = -100;
      const y = myRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' }); // For Chrome, Firefox, IE and Opera});
    }
  };

  return (
    <div
      ref={myRef}
      className={`relative overflow-hidden bg-surface-container min-h-29 rounded-lg filter grayscale-50 flex transition-all ${
        itemLimited ? 'filter grayscale-50' : 'hover:shadow-md hover:border-color-outline'
      } flex-col ${props.expanded ? 'lg:col-span-2 shadow-md ' : ''}`}
    >
      <div
        className={`z-10 flex items-center px-4 py-3 ${itemLimited ? 'cursor-default' : 'cursor-pointer'}`}
        onClick={(event) =>
          props.disabled || itemLimited || !props.provider.store_acceptOrders ? null : clickContainer(event)
        }
      >
        <div>
          <p className="font-semibold">{props.menuItem.names[props.locale]}</p>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {getCategoryFromCode(props.menuItem.category_code, props.locale)}
          </p>
        </div>
        <span className="flex-1"></span>
        <span className="mr-2">
          {props.expanded
            ? (
                count *
                (props.menuItem.price +
                  ingPrice +
                  (extras && extras.length
                    ? extras
                        .map((e) => ((e.is_multiple && e.count) > 1 ? e.count * e.price : e.price))
                        .reduce((acc, price) => acc + price, 0)
                    : 0))
              ).toFixed(2)
            : props.menuItem.price.toFixed(2)}
          €
        </span>
        {props.expanded ? (
          <>
            {itemLimited && !props.disabled && (
              <ExclamationCircleIcon className="h-5 w-5 mx-2 text-error focus:outline-none"></ExclamationCircleIcon>
            )}

            <button
              disabled={props.disabled || itemLimited || !props.provider.store_acceptOrders}
              className={`${iconButton}`}
              onClick={(event) => {
                props.toggle(props.menuItem.uid);
              }}
            >
              <ChevronUpIcon className={`h-5 w-5`}></ChevronUpIcon>
            </button>
            <div className="flex items-center ml-2">
              <button
                disabled={props.disabled || itemLimited || !props.provider.store_acceptOrders}
                className={`${iconButton}`}
                onClick={decrementCount}
              >
                <MinusIcon className={`pointer-events-none h-5 w-5`} />
              </button>
              <span className="text-lg mx-2">{count}</span>
              <button
                disabled={props.disabled || itemLimited || !props.provider.store_acceptOrders}
                className={`${iconButton}`}
                onClick={incrementCount}
              >
                <PlusIcon className={`pointer-events-none h-5 w-5`} />
              </button>
            </div>
          </>
        ) : (
          <>
            {itemLimited && (
              <ExclamationCircleIcon
                data-tip={`${t('order:itemlimited')} ${limitedTimes}`}
                className="h-5 w-5 mx-2 text-error focus:outline-none"
              ></ExclamationCircleIcon>
            )}

            {!props.menuOnly && (
              <button
                disabled={props.disabled || itemLimited || !props.provider.store_acceptOrders}
                className={`${iconButton}`}
                onClick={(event) => {
                  props.toggle(props.menuItem.uid);
                }}
              >
                <ChevronDownIcon className={`h-5 w-5`}></ChevronDownIcon>
              </button>
            )}

            <ReactTooltip effect="solid" />
          </>
        )}
      </div>
      {props.menuItem.imageUrl
        ? !props.expanded && (
            <div
              className={`transition-all duration-100 absolute -bottom-12 -right-4 ${
                itemLimited ? 'filter grayscale grayscale-100' : ''
              }`}
            >
              <Image
                width={104}
                height={104}
                src={props.menuItem.imageUrl}
                alt={props.menuItem.names[props.locale] || 'Item pic'}
              />
            </div>
          )
        : !props.expanded && (
            <div
              className={`transition-all duration-100 absolute -bottom-12 -right-4 ${
                itemLimited ? 'filter grayscale grayscale-100' : ''
              }`}
            >
              <Image
                width={104}
                height={104}
                src={`/placeholders/${getCategoryAvatarFromCode(props.menuItem.category_code, props.locale)}`}
                alt={props.menuItem.names[props.locale] || 'Item pic'}
              />
            </div>
          )}

      {isCombo && !props.expanded ? (
        <div className="bg-tertiary text-on-tertiary absolute bottom-2 right-2 rounded-md py-1 px-2 text-xs shadow">
          {t('combo')}
        </div>
      ) : null}

      <div
        onClick={(event) =>
          props.disabled || itemLimited || !props.provider.store_acceptOrders ? null : clickContainer(event)
        }
        className={`px-4 pr-24 mb-2 ${props.disabled || itemLimited || !props.provider.store_acceptOrders ? 'cursor-default' : 'cursor-pointer'}`}
      >
        {props.menuItem.descriptions && props.menuItem.descriptions[props.locale] ? (
          <span className="text-sm text-gray-500 dark:text-gray-400 italic my-2">
            {props.menuItem.descriptions[props.locale]}
          </span>
        ) : (
          <span>&nbsp;</span>
        )}
        {props.menuItem?.multi_tags && props.menuItem.multi_tags[props.locale] ? (
          <div className="flex flex-wrap my-2">
            {props.menuItem?.multi_tags[props.locale].map((tag) => (
              <span key={tag} className="text-grey mr-4">
                #{tag}
              </span>
            ))}
          </div>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>

      {props.expanded ? (
        <div className="px-4 pt-4 border-t border-outline-variant pb-4 flex flex-col">
          {showView === ExpandedView.PIZZA_CONFIGURATOR ? (
            <PizzaConfigurator
              menuItem={props.menuItem}
              loadedMenuItems={props.loadedMenuItems}
              selectedIngredients={selectedIngredients}
              locale={props.locale}
              emitSliceData={(sd) => setSliceData(sd)}
              emitSliceCount={(sc) => setSliceCount(sc)}
              ingredientsForSlicesDisabled={props.ingredientsForSlicesDisabled}
            />
          ) : showView === ExpandedView.INGREDIENTS ? (
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="outline-none flex justify-between w-full px-4 py-4 text-sm font-medium text-left transition-all bg-surface-container-high text-on-surface hover:shadow dark:bg-opacity-50 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-bringmos focus-visible:ring-opacity-75">
                    <span>{t('change_ingredients')}</span>
                    <ChevronUpIcon
                      className={`transition-all ${open ? '' : 'transform rotate-180'} w-5 h-5 text-primary `}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                    <IngredientSelect
                      hidePrice={false}
                      hidePreview={false}
                      emitIngredients={setIngredientsToMenuItem}
                      selectedIngredients={selectedIngredients}
                      locale={props.locale}
                    />
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ) : showView === ExpandedView.COMBO ? (
            <Combo
              menuItem={props.menuItem}
              loadedMenuItems={props.loadedMenuItems}
              loadedExtras={loadedExtras}
              emitComboData={setComboData}
            />
          ) : (
            <div>
              <TextInput
                id="comment"
                label={t('order:additional_info')}
                value={extrasValue}
                onChange={updateExtrasValue}
                placeholder={t('order:additional_info_placeholder')}
              />
            </div>
          )}

          {showView !== ExpandedView.COMBO &&
          loadedExtras &&
          loadedExtras[props.menuItem.category_code] &&
          loadedExtras[props.menuItem.category_code].length ? (
            <Extras
              categoryCode={props.menuItem.category_code}
              locale={props.locale}
              emitExtras={setExtrasToMenuItem}
            ></Extras>
          ) : null}

          <div className="">
            {props.menuItem.allergics && props.menuItem.allergics.length ? (
              <Allergics menuItem={props.menuItem}></Allergics>
            ) : null}
          </div>
          <Button
            disabled={
              props.disabled ||
              itemLimited ||
              (sliceData && sliceData.length !== sliceCount) ||
              sliceData?.findIndex((slice) => slice.ingredients?.length === 0 && !slice.from_menu_item) > -1
            }
            onClick={() => addMenuItemToOrder()}
            className="self-end z-10 mt-4 flex items-center"
          >
            <i className="mr-2 las la-cart-plus text-3xl"></i>
            <span>{t('addtocart')}</span>
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default MenuItemComponent;
