import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';

import ingredientsStore from '../lib/ingredients';
import styles from '../styles/Pizza.module.scss';
import { Ingredient, LanguageMap, MenuItem, PartialMenuitem, SliceData, SupportedCategories } from '../types/src';
import { mapIngredientToLocale } from '../utils/language-helper';
import { isItemLimited } from '../utils/menuItem';
import IngredientSelect, { IngredientReturnType } from './IngredientSelect';
import PizzaSelect from './MenuItemSelect';
import SliceNumber from './SliceNumber';
import SliceSelection from './SliceSelection';

export interface SliceFromIngredients {
  ingredients: IngredientReturnType;
}
export interface SliceFromMenuitem {
  menuItem: MenuItem;
}

type Slice = SliceFromIngredients | SliceFromMenuitem;

export type SlicesData = {
  [id: number]: Slice;
};

type Props = {
  menuItem: MenuItem;
  loadedMenuItems: MenuItem[];
  selectedIngredients: Ingredient[];
  locale: string;
  ingredientsForSlicesDisabled: boolean;
  emitSliceData: (sliceData: Array<SliceData>) => void;
  emitSliceCount: (count: number) => void;
};

export default function PizzaConfigurator(props: Props) {
  const [currentId, setCurrentId] = useState(0);
  const [selectionType, setSelectionType] = useState('pizza');

  const min = props.menuItem.slices?.min;
  const max = props.menuItem.slices?.max;
  const initialCount = props.menuItem.slices?.available && min && max ? (min === max ? max : max - min + 1) : 1;
  const [count, setCount] = useState(initialCount);

  const [sliceData, setSliceData] = useState<SlicesData>();

  const { t } = useTranslation('provider');

  const loadedIngredients = ingredientsStore((state) => (state as any).ingredients);

  useCallback(() => {
    const initialSlices = setSliceDataForCount(initialCount);
    setSliceData(initialSlices);
  }, [loadedIngredients]);

  useEffect(() => {
    calculateAndEmitSliceData();
  }, []);

  useEffect(() => {
    if (currentId >= count) {
      setCurrentId(count - 1);
    }
  }, [count]);

  useEffect(() => {
    props.emitSliceCount(count);
    calculateAndEmitSliceData();
    createPie(121, 122, 116, count == 1 ? 0 : count);
    fillCurrent(currentId);
  }, [count, sliceData]);

  function setCurrentSlice(slice) {
    setCurrentId(slice);
    fillCurrent(slice);
  }

  function fillCurrent(slice) {
    const pie = document.getElementById('pie');
    const part = document.getElementById(slice);

    const paths = Array.from(pie ? pie.getElementsByTagName(`path`) : []);
    if (paths && part) {
      paths.forEach((i) => {
        i.style.fill = '#00000050';
      });
      part.style.fill = 'transparent';
      part.style.stroke = 'transparent';
    }
  }

  function setSlice(slice: SliceFromIngredients | SliceFromMenuitem, type: string) {
    const sliceDataTemp: any = Object.assign({}, sliceData);
    if (type === 'ingredient') {
      sliceDataTemp[currentId] = slice as SliceFromIngredients;
    } else if (type === 'pizza') {
      sliceDataTemp[currentId] = slice as SliceFromMenuitem;
    }
    setSliceData(sliceDataTemp);
  }

  function setSliceDataForCount(count): {
    [id: number]: { menuItem?: MenuItem; ingredients: IngredientReturnType };
  } {
    const changedIngs: {
      [id: number]: { menuItem?: MenuItem; ingredients: IngredientReturnType };
    } = {};

    for (let i = 0; i < count; i++) {
      changedIngs[i] = {
        ingredients: {
          ingredients: props.selectedIngredients,
          multi_ingredients: {
            de: mapIngredientToLocale(props.selectedIngredients, 'de'),
            it: mapIngredientToLocale(props.selectedIngredients, 'it'),
            en: mapIngredientToLocale(props.selectedIngredients, 'en'),
          },
        },
      };
    }

    return changedIngs;
  }

  function calculateAndEmitSliceData() {
    const slices = sliceData
      ? Object.entries(sliceData).map(([sliceId, value]) => {
          const partialMenuitem: PartialMenuitem = {
            id: (value as SliceFromMenuitem).menuItem?.uid,
            names: (value as SliceFromMenuitem).menuItem?.names ?? new LanguageMap(),
            descriptions: (value as SliceFromMenuitem).menuItem?.descriptions ?? new LanguageMap(),
            multi_ingredients: (value as SliceFromMenuitem).menuItem?.multi_ingredients ?? new LanguageMap(),
            imageUrl: (value as SliceFromMenuitem).menuItem?.imageUrl ?? '',
          };

          const sliceData: SliceData = {
            from_menu_item: (value as SliceFromMenuitem).menuItem && partialMenuitem ? partialMenuitem : null,
            ingredients: (value as SliceFromIngredients).ingredients?.ingredients
              ? (value as SliceFromIngredients).ingredients.ingredients
              : [],
            multi_ingredients:
              (value as SliceFromMenuitem).menuItem && partialMenuitem
                ? null
                : ((value as SliceFromIngredients).ingredients?.multi_ingredients ?? { de: '', it: '', en: '' }),
          };
          return sliceData;
        })
      : [];

    const relevantData = count > 1 ? slices.slice(0, count) : slices;
    props.emitSliceData(relevantData);
  }

  return (
    <div className="flex flex-col items-center">
      {props.menuItem?.slices?.available && min && max && min !== max && (
        <div className="flex-1">
          <SliceNumber min={min} max={max} number={count} changedNumber={setCount}></SliceNumber>
        </div>
      )}
      <div className="flex flex-col w-full">
        <div className="flex-1 flex items-center justify-center">
          <div className="h-60 w-60 relative my-4">
            <Image width={240} height={240} className="" alt={`pizzagound`} src="/placeholders/pizza_ground.png" />
            {sliceData && props.locale && <ClippedImages sliceData={sliceData} locale={props.locale}></ClippedImages>}

            <svg className={styles.svg} viewBox="0 0 240 240" id="pie"></svg>
          </div>
        </div>
        <div className="flex-1">
          {props.menuItem?.slices?.available && min && max && (
            <SliceSelection
              current={currentId}
              number={count}
              changedSelection={setCurrentSlice}
              sliceData={sliceData}
            ></SliceSelection>
          )}
        </div>
      </div>
      {Array.from(Array(count).keys()).map((_, id) => {
        return (
          <div key={`ing${id}`} className={`${currentId == id ? 'block w-full' : 'hidden'}`}>
            <div className="bg-surface-container-high text-on-surface sm:mt-8 mt-6 rounded-md px-4">
              {!props.ingredientsForSlicesDisabled && (
                <div className="pt-4">
                  <p className="text-center mb-4 text-sm"> {t('slices.pizza_or_ingredients_description')} </p>

                  <div
                    className="flex flex-row w-fit justify-center rounded-lg mb-4 mx-auto                    
"
                  >
                    <button
                      onClick={() => setSelectionType('pizza')}
                      type="button"
                      className={`${selectionType === 'pizza' ? 'bg-primary text-on-primary' : 'bg-surface text-on-surface'}
  border-none transition-all mr-2 flex-1 rounded-md py-2 px-4 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 w-fit`}
                    >
                      {t('from_menuitem')}
                    </button>
                    {!props.ingredientsForSlicesDisabled && (
                      <button
                        onClick={() => setSelectionType('ingredient')}
                        type="button"
                        className={`${selectionType === 'ingredient' ? 'bg-primary text-on-primary' : 'bg-surface text-on-surface'}
                      border-none transition-all mr-2 flex-1 rounded-md py-2 px-4 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 w-fit`}
                      >
                        {t('from_ingredients')}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {selectionType === 'ingredient' ? (
                <IngredientSelect
                  key={id}
                  hidePreview={true}
                  hidePrice={true}
                  emitIngredients={(value) => setSlice({ ingredients: value }, 'ingredient')}
                  selectedIngredients={props.selectedIngredients}
                  locale={props.locale}
                />
              ) : (
                <PizzaSelect
                  loadedMenuItems={props.loadedMenuItems.filter(
                    (m) =>
                      m.category_code === 'pizza' &&
                      !isItemLimited(m) &&
                      (!m.slices || m.slices.available === false) &&
                      !m.combo_config,
                  )}
                  key={id}
                  emitItems={([value]) => setSlice({ menuItem: value }, 'pizza')}
                  locale={props.locale}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );

  function createPie(cx, cy, r, slices) {
    const clickFcn = (event) => {
      const slice = event.target.getAttribute('id');
      if (slice !== undefined) {
        const sliceAsNumber = Number(slice);
        setCurrentSlice(sliceAsNumber);
      } else {
        return;
      }
    };
    const pie = document.getElementById('pie');
    pie.innerHTML = '';
    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    pie.appendChild(defs);

    for (var i = 0; i < slices; i++) {
      const clipPath = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
      clipPath.setAttribute('id', `clip${i}`);
      clipPath.setAttribute('key', `clip${i}`);

      const fromAngle = (i * 360) / slices;
      const toAngle = ((i + 1) * 360) / slices;

      const fromCoordX = cx + r * Math.cos((fromAngle * Math.PI) / 180);
      const fromCoordY = cy + r * Math.sin((fromAngle * Math.PI) / 180);
      const toCoordX = cx + r * Math.cos((toAngle * Math.PI) / 180);
      const toCoordY = cy + r * Math.sin((toAngle * Math.PI) / 180);

      const d =
        'M' +
        cx +
        ',' +
        cy +
        ' L' +
        fromCoordX +
        ',' +
        fromCoordY +
        ' A' +
        r +
        ',' +
        r +
        ' 0 0,1 ' +
        toCoordX +
        ',' +
        toCoordY +
        'z';

      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      path.setAttributeNS(null, 'd', d);

      const visiblePath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      visiblePath.setAttributeNS(null, 'd', d);
      visiblePath.setAttribute('id', `${i}`);
      visiblePath.setAttribute('key', `${i}`);
      visiblePath.addEventListener('click', clickFcn, false);
      visiblePath.style.stroke = 'transparent';

      clipPath.appendChild(path);
      defs.appendChild(clipPath);

      pie.appendChild(visiblePath);
    }
  }
}

function ClippedImages({ sliceData, locale }) {
  const categories = SupportedCategories[locale];

  const images = Object.entries(sliceData).map(([key, value]) => {
    return (value as SliceFromIngredients).ingredients ? (
      (value as SliceFromIngredients).ingredients.ingredients
        .map((ing) => {
          if (ing.image_url) {
            return (
              <img
                className="absolute inset-0 w-full h-full"
                key={`slice${key}${ing.uid}`}
                alt={`pizza slice`}
                width={240}
                height={240}
                style={{ clipPath: `url(#clip${key})` }}
                src={ing.image_url}
              />
            );
          }
        })
        .flat()
        .filter((img) => !!img)
    ) : (value as SliceFromMenuitem).menuItem ? (
      (value as SliceFromMenuitem).menuItem.imageUrl ? (
        <img
          className="absolute inset-0 w-full h-full"
          key={`slice${key}${(value as SliceFromMenuitem).menuItem.uid}`}
          alt={`pizza slice`}
          width={240}
          height={240}
          style={{ clipPath: `url(#clip${key})` }}
          src={(value as SliceFromMenuitem).menuItem.imageUrl}
        />
      ) : (
        <img
          className="absolute inset-0 w-full h-full"
          key={`slice${key}${(value as SliceFromMenuitem).menuItem.uid}`}
          alt={`pizza slice`}
          width={240}
          height={240}
          style={{ clipPath: `url(#clip${key})` }}
          src={`/placeholders/${
            categories.find((c) => c.code == (value as SliceFromMenuitem).menuItem.category_code).avatar
          }`}
        />
      )
    ) : null;
  });

  return <>{images}</>;
}
