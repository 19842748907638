import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { UserContext } from '../lib/context';
import orderStore, { Payment, Type } from '../lib/order';
import { COUNTRIES, Country, Location, Provider, User } from '../types/src';
import { findCountryOfCurrentPhone, parseNumber } from '../utils/phone';
import CheckoutForm from './CheckoutForm';
import ClientDataForOrder from './ClientDataForOrder';
import OrderPayment from './OrderPayment';
import OrderTiming from './OrderTiming';
import OrderType from './OrderType';

type Props = {
  provider: Provider;
  handlePrevious: () => void;
  handleNext: any;
  disabled?: boolean;
};

export default function CompleteOrder({ provider, disabled, handlePrevious }: Props) {
  const { user, clientData } = useContext(UserContext);
  const { t } = useTranslation('provider');

  const router = useRouter();

  const { type } = router.query as { type: Type };

  const subtotal = orderStore((state) => (state as any).subtotal);
  const [deliveryPrice, setDeliveryPrice] = useState<number>(0);

  const [client, setClient] = useState<any>();

  const validateClientData: boolean =
    type === Type.DELIVERY
      ? !(client?.client_name && client?.client_town && client?.client_zip && client?.client_address && client?.client_phone)
      : type === Type.PICKUP
        ? !(client?.client_name && client?.client_phone)
        : false;

  const {
    register,
    control,
    setValue,
    formState: { isValid, errors, touchedFields },
  } = useForm({
    defaultValues: clientData,

    mode: 'onChange',
  });

  const [country, setCountry] = useState<Country>(COUNTRIES[0]);

  useEffect(() => {
    if (clientData?.client_phone) {
      const country = findCountryOfCurrentPhone(clientData.client_phone);
      if (country) {
        setCountry(country);
      }
    }
  }, []);

  function findCountry(phone: string | undefined) {
    if (phone && country) {
      const formatted = parseNumber(phone, country);
      if (formatted && phone !== formatted) {
        setValue('client_phone', formatted);
      }
    }
  }

  findCountry(
    useWatch({
      control,
      name: 'client_phone',
      defaultValue: '',
    }),
  );

  function setClientData(data: Partial<User>): void {
    const partialData: Partial<User> = {
      client_name: data.client_name,
      client_town: data.client_town,
      client_zip: data.client_zip,
      client_address: data.client_address,
      client_phone: data.client_phone,
      client_country: country ? country.isoCode : 'IT',
    };

    // only set deliveryprice for delivery type
    if (isValid && type === Type.DELIVERY) {
      let location: Location | undefined = undefined;

      if (provider.delivery_locations) {
        location = provider.delivery_locations
          .filter((l) => l.names && l.names[router.locale ?? 'de'])
          .find((location) => location.names && location.names[router.locale ?? 'de'] === data.client_town);
      }

      if (location && location.price !== undefined && deliveryPrice !== location.price) {
        setDeliveryPrice(location.price);
      }
    }

    if (JSON.stringify(client) !== JSON.stringify(partialData)) {
      setClient(partialData);
    }
  }

  setClientData(
    useWatch({
      control,
    }),
  );

  function changeCountry(country) {
    setCountry(country);
    setValue('client_phone', '');
  }

  const calcprice = subtotal + (provider.free_delivery_from && subtotal >= provider.free_delivery_from ? 0 : deliveryPrice);

  const [payment, setPayment] = useState<Payment>();

  return (
    <div className="flex flex-col pt-10">
      <div className="mt-8 mb-4 container mx-auto max-w-6xl flex flex-row justify-between">
        <h2 id="complete-top-title" className="text-xl">
          {t('complete_order')}
        </h2>
      </div>

      <div className="container mx-auto max-w-6xl border-t border-dividerlight dark:border-dividerdark">
        <h2 className="py-4 mt-6 text-2xl ">{t('type')}</h2>

        {provider && <OrderType provider={provider} />}
      </div>

      <div className="pb-6 pt-10 container mx-auto max-w-6xl border-t border-dividerlight dark:border-dividerdark">
        {user ? (
          <ClientDataForOrder
            provider={provider}
            control={control}
            country={country}
            register={register}
            emitCountry={changeCountry}
            onlyNameAndPhone={type === Type.PICKUP}
            errors={errors}
            touchedFields={touchedFields}
          ></ClientDataForOrder>
        ) : (
          <div></div>
        )}
      </div>

      <div className="container mx-auto max-w-6xl border-t border-dividerlight dark:border-dividerdark">
        <h2 className="py-4 mt-6 text-2xl ">{t('payment')}</h2>

        <OrderPayment provider={provider} type={type} payment={payment} setPayment={setPayment} />
      </div>

      <div className="pb-4 container mx-auto max-w-6xl border-t border-dividerlight dark:border-dividerdark">
        <h2 className="py-4 mt-6 text-2xl ">{t('timing')}</h2>

        <OrderTiming provider={provider} type={type} />
      </div>

      <div className="pb-8 container mx-auto max-w-6xl border-t border-dividerlight dark:border-dividerdark">
        <div className="block text-sm  py-2 border-b border-dividerlight dark:border-dividerdark">
          <div className="flex flex-col py-1 items-end">
            <p className="text-base text-gray-500 dark:text-gray-400">{t('total')}</p>
            {/* todo add deliveryprice */}
            {payment === Payment.CREDIT ? (
              <div className="py-1 text-black dark:text-white flex justify-end">
                <p>{t('incl_creditcard_fee')}:&nbsp;</p>
                <p>{((provider.creditcard_fee ?? 0) * calcprice).toFixed(2)}€</p>
              </div>
            ) : null}
            <p className="text-gray-500 dark:text-gray-400 max-w-md text-xs text-right">{t('totaldisclaimer')}</p>
            {provider.deliveryAvailable && provider.free_delivery_from && subtotal >= provider.free_delivery_from ? (
              <p className="text-gray-500 dark:text-gray-400 max-w-md text-xs text-right">{`${t('free')}`}</p>
            ) : type === Type.DELIVERY ? (
              <p className="text-gray-500 dark:text-gray-400 max-w-md text-xs text-right">{`${t('incl_delivery', {
                value: deliveryPrice.toFixed(2),
              })}`}</p>
            ) : null}
            {payment === Payment.CREDIT ? (
              <p className="text-xl">
                {(type === Type.DELIVERY ? (provider.creditcard_fee ?? 0) * calcprice + calcprice : subtotal).toFixed(2)}€
              </p>
            ) : (
              <p className="text-xl">{(type === Type.DELIVERY ? calcprice : subtotal).toFixed(2)}€</p>
            )}
          </div>

          {provider.min_order_price && subtotal < provider.min_order_price ? (
            <div className="flex py-2">
              <p className="flex-grow">{t('minorderprice')}</p>
              <p className="text-sm">{provider.min_order_price.toFixed(2)}€</p>
            </div>
          ) : null}
        </div>

        <div className="flex flex-col py-4">
          <p className="text-xs my-4 text-gray-500 dark:text-gray-400">*{t('common:tosaccepthint')}</p>
          <CheckoutForm
            provider={provider}
            name={`${t('paymentto')} ${provider.name}`}
            disabled={type === Type.PICKUP ? disabled : !isValid || disabled}
            client={client}
            clientDataInvalid={validateClientData}
            deliveryPrice={deliveryPrice}
            type={type}
            payment={payment}
          />
        </div>
      </div>
    </div>
  );
}
