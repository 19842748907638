import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Extra, MenuItem, MenuItemOrder } from '../types/src';
import { isItemLimited } from '../utils/menuItem';
import ComboSelection from './ComboSelection';
import Extras from './Extras';
import MenuItemSelect from './MenuItemSelect';

interface ComboData {
  [selection: number]: Partial<MenuItemOrder>[];
}
export default function Combo({
  menuItem,
  loadedMenuItems,
  loadedExtras,
  emitComboData,
}: {
  menuItem: MenuItem;
  loadedMenuItems: MenuItem[];
  loadedExtras: Extra[];
  emitComboData: (selection: Partial<MenuItemOrder>[]) => void;
}) {
  const [selection, setSelection] = useState(0);
  const [comboData, setComboData] = useState<ComboData>([]);
  const { locale } = useRouter();

  function setCombo(items: Partial<MenuItemOrder>[]) {
    const comboDataTemp: any = Object.assign([], comboData);

    const reducedItems: Array<Partial<MenuItemOrder>> = items.map((i) => {
      return Object.assign(
        {},
        {
          count: 1,
          category_code: i.category_code,
          descriptions: i.descriptions,
          extraItems: [],
          extras: '',
          id: i.uid,
          imageUrl: i.imageUrl ?? '',
          multi_ingredients: i.multi_ingredients ?? {},
          names: i.names,
        },
      );
    });

    const summedItems = reducedItems.reduce((previousValueArray, currentValue) => {
      const index = previousValueArray.findIndex((m) => m.id === (currentValue as any).id);
      if (index > -1) {
        ++previousValueArray[index].count;
        return previousValueArray;
      } else {
        return [currentValue, ...previousValueArray];
      }
    }, []);

    comboDataTemp[selection] = summedItems;
    setComboData(comboDataTemp);
  }

  function setExtrasToSelection(extras) {
    const copy = Object.assign([], comboData);
    if (!copy[selection]) {
      copy[selection][0].extraItems = extras;
    }
    setComboData(copy);
  }

  useEffect(() => {
    const flattened = Object.values(comboData).flat();
    emitComboData(flattened);
  }, [selection, comboData]);

  return (
    <div className="rounded-md">
      <div className="flex flex-col items-center rounded-lg">
        <ComboSelection selection={selection} config={menuItem.combo_config} changedSelection={setSelection} />

        <div className="pt-6 self-stretch">
          {menuItem.combo_config.map((combo, index) => (
            <div key={`menuitemarray-${index}`} className={index === selection ? 'block' : 'hidden'}>
              <MenuItemSelect
                loadedMenuItems={loadedMenuItems.filter(
                  (m) =>
                    !isItemLimited(m) &&
                    (!m.slices || m.slices.available === false) &&
                    !m.combo_config &&
                    combo.category_codes.includes(m.category_code) &&
                    (combo.ids && combo.ids.length ? combo.ids.includes(m.uid) : true),
                )}
                key={'menuitemselection'}
                emitItems={setCombo}
                count={combo.max_selection}
                locale={locale}
              />
            </div>
          ))}
        </div>

        <div className="self-stretch">
          {menuItem.combo_config.map((combo, index) => (
            <div key={`menuitemarray-${index}`} className={index === selection ? 'block' : 'hidden'}>
              {(!combo.max_selection || combo.max_selection === 1) &&
              loadedExtras &&
              loadedExtras[combo.category_codes[index]] &&
              loadedExtras[combo.category_codes[index]].length ? (
                <Extras
                  categoryCode={combo.category_codes[index]}
                  locale={locale}
                  emitExtras={setExtrasToSelection}
                ></Extras>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
