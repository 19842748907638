import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import { XCircleIcon } from '@heroicons/react/24/solid';
import { localizedAttribute } from '../lib/helper';
import orderStore from '../lib/order';
import { MenuItemOrder } from '../types/src';

interface Props {
  menuItems: MenuItemOrder[];
  description?: boolean;
  extras?: boolean;
}

export default function SideCartItems({ menuItems, description, extras }: Props) {
  const router = useRouter();
  const { locale } = router;
  const { t } = useTranslation('provider');

  const removeMenuItemIndex = orderStore((state: any) => state.removeMenuItemIndex);

  return (
    <div className={`flex flex-col rounded-lg`}>
      {menuItems && menuItems.length ? (
        menuItems.map((menuItem, i) => (
          <div key={`${menuItem.uid}${i}`} className="flex border-b py-2 border-outline-variant">
            <div className="flex flex-col">
              <p className="font-semibold">
                {menuItem.count}x {localizedAttribute(menuItem.names, locale)}
              </p>
              {description ? <p className="text-sm">{localizedAttribute(menuItem.descriptions, locale)}</p> : null}

              {menuItem.extras && <p className="text-sm">{menuItem.extras}</p>}

              {menuItem.combo_data && menuItem.combo_data.selection && menuItem.combo_data.selection.length
                ? menuItem.combo_data.selection.map((comboMenuItem, index) => (
                    <p key={'combo' + index} className="flex flex-col text-sm">
                      <span>
                        {`${comboMenuItem.count}x `}
                        {comboMenuItem && comboMenuItem.names && comboMenuItem.names[locale]
                          ? `${comboMenuItem.names[locale]}`
                          : ''}
                      </span>

                      {comboMenuItem.extraItems && comboMenuItem.extraItems.length
                        ? comboMenuItem.extraItems.map((extra, i) => {
                            return (
                              <span key={i} className="pl-4 text-xs">
                                <span>+</span> {extra.count ? `${extra.count}x` : null} {extra.names[locale]}
                              </span>
                            );
                          })
                        : null}
                    </p>
                  ))
                : null}

              {menuItem.added_ingredients && Object.keys(menuItem.added_ingredients).length ? (
                <p className="text-sm">
                  {t('provider:with')}:{' '}
                  {Object.entries(menuItem.added_ingredients)
                    .map(([ingId, ing]) => ing[locale])
                    .join(', ')
                    .replace(/, ([^,]*)$/, ` ${t('provider:and')} $1`)}
                </p>
              ) : null}

              {menuItem.removed_ingredients && Object.keys(menuItem.removed_ingredients).length ? (
                <p className="text-sm">
                  {t('provider:without')}:{' '}
                  {Object.entries(menuItem.removed_ingredients)
                    .map(([ingId, ing]) => ing[locale])
                    .join(', ')
                    .replace(/, ([^,]*)$/, ` ${t('provider:and')} $1`)}{' '}
                </p>
              ) : null}

              {menuItem.slice_data && menuItem.slice_data.length
                ? menuItem.slice_data.map((slice, index) => (
                    <p key={'slice' + index} className="text-sm">
                      {`${t('slices.singular')} ${index + 1}`}:{' '}
                      {slice.from_menu_item && slice.from_menu_item.names && slice.from_menu_item.names[locale]
                        ? slice.from_menu_item.names[locale]
                        : slice.multi_ingredients && slice.multi_ingredients[locale]
                          ? slice.multi_ingredients[locale]
                          : ''}
                    </p>
                  ))
                : null}

              {extras && menuItem.extraItems && menuItem.extraItems.length
                ? menuItem.extraItems.map((extra, i) => {
                    return (
                      <p key={'extra' + i} className="text-sm">
                        <span>+</span> {extra.count ? `${extra.count}x` : null} {extra.names[locale]}
                      </p>
                    );
                  })
                : null}
            </div>

            <span className="flex-grow"></span>

            <div className="h-full flex-col justify-end items-end">
              <span className="text-sm">{menuItem.totalprice ? menuItem.totalprice.toFixed(2) : (0).toFixed(2)}€</span>
            </div>
            <button
              className="block h-6 w-6 flex-shrink-0 ml-4 focus:outline-none"
              onClick={() => removeMenuItemIndex(i, locale)}
            >
              <XCircleIcon className="text-secondary hover:text-error transition-all" />
            </button>
          </div>
        ))
      ) : (
        <p className="text-sm border-b py-2 border-outline-variant">{t('provider:no_items_in_cart')}</p>
      )}
    </div>
  );
}
