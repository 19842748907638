import { RadioGroup } from '@headlessui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import { SliceFromIngredients, SliceFromMenuitem, SlicesData } from './PizzaConfigurator';

type Props = {
  current: number;
  number: number;
  changedSelection: (number) => void;
  sliceData: SlicesData;
};

export default function SliceSelection({ current, number, changedSelection, sliceData }: Props) {
  const { t } = useTranslation('provider');
  const { locale } = useRouter();

  const slices: { name: string; number: number }[] = Array.from(Array(number).keys()).map((_, index) => {
    return {
      name: `slices.name`,
      number: index,
    };
  });

  return (
    <>
      <p className="text-center mb-4 text-sm"> {t('slices.select_description')} </p>

      <div className="w-fit bg-primary-container p-2 rounded-xl mx-auto">
        <RadioGroup value={current} onChange={changedSelection}>
          <div className="flex flex-row space-x-2 justify-center w-full">
            {slices.map((slice) => (
              <RadioGroup.Option
                key={slice.number}
                value={slice.number}
                className={({ checked }) =>
                  `${checked ? 'bg-primary text-on-primary' : 'bg-surface text-on-surface'}
                relative rounded-lg shadow-md p-2 cursor-pointer flex focus:outline-none`
                }
              >
                {({ checked }) => (
                  <>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label as="p" className={`whitespace-nowrap font-semibold`}>
                            {slice.number ? `${t('slices.singular')} ${slice.number + 1}` : `${t('slices.singular')} 1`}
                          </RadioGroup.Label>

                          <RadioGroup.Description as="span" className={`inline`}>
                            {sliceData && (sliceData[slice.number] as SliceFromIngredients)?.ingredients && (
                              <div className="flex items-center">
                                <p>
                                  {(sliceData[slice.number] as SliceFromIngredients).ingredients &&
                                  (sliceData[slice.number] as SliceFromIngredients).ingredients.ingredients.length
                                    ? (sliceData[slice.number] as SliceFromIngredients).ingredients.ingredients
                                        .map((ing) => ing.names[locale])
                                        .join(', ')
                                        .replace(/, ([^,]*)$/, ` ${t('provider:and')} $1`)
                                    : null}
                                </p>
                              </div>
                            )}
                            {sliceData && (sliceData[slice.number] as SliceFromMenuitem)?.menuItem && (
                              <div className="flex items-center">
                                <p>
                                  {(sliceData[slice.number] as SliceFromMenuitem).menuItem?.names
                                    ? (sliceData[slice.number] as SliceFromMenuitem).menuItem.names[locale]
                                    : null}
                                </p>
                              </div>
                            )}
                          </RadioGroup.Description>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path d="M7 13l3 3 7-7" stroke="#fff" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
