import * as Sentry from '@sentry/browser';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { logEcoommercePurchase } from '../lib/analytics';
import { UserContext } from '../lib/context';
import { saveClient, sendOrder } from '../lib/firebase';
import orderStore, { Payment, Type } from '../lib/order';
import { Order, Provider } from '../types/src';
import { fetchPostJSON } from '../utils/api-helpers';
import getStripe from '../utils/get-stripejs';
import { formatAmountForDisplay } from '../utils/stripe-helpers';
import Spinner from './Spinner';
const { version } = require('../package.json');

type Props = {
  provider: Provider;
  name: string;
  client: any;
  clientDataInvalid: boolean;
  disabled: boolean;
  deliveryPrice: number;
  type: Type;
  payment: Payment;
};

const CheckoutForm = ({ provider, type, payment, name, client, clientDataInvalid, disabled, deliveryPrice }: Props) => {
  const { t } = useTranslation('provider');
  const router = useRouter();
  const { locale } = router;
  const { user } = useContext(UserContext);

  const subtotal = orderStore((state) => (state as any).subtotal);

  const menuItems = orderStore((state) => (state as any).menuItems);
  const targetDate = orderStore((state) => (state as any).targetDate);

  const [loading, setLoading] = useState(false);

  async function handleCheckout(order: Order) {
    // Create a Checkout Session.
    const response = await fetchPostJSON('/api/checkout_sessions', {
      amount:
        type === Type.PICKUP
          ? subtotal
          : payment === Payment.CREDIT
            ? subtotal +
              deliveryPrice +
              provider.creditcard_fee *
                (subtotal + (provider.free_delivery_from && subtotal >= provider.free_delivery_from ? 0 : deliveryPrice))
            : subtotal + (provider.free_delivery_from && subtotal >= provider.free_delivery_from ? 0 : deliveryPrice),
      name: name,
      providerId: provider.uid,
      providerlink: provider.link,
      creditcard_fee: provider.creditcard_fee ?? 0,
    });

    if (response.statusCode === 500) {
      console.error(response.message);
      Sentry.captureException(response.message);
      return;
    }

    return sendOrder({
      payment_account_id: response.stripeAccountId,
      payment_intent: response.payment_intent,
      payment_checkout_id: response.id,
      ...order,
    }).then(({ id }) => {
      return getStripe(response.stripeAccountId)
        .then((stripe) => {
          if (stripe) {
            return stripe
              .redirectToCheckout({
                // Make the id field from the Checkout Session creation API response
                // available to this file, so you can provide it as parameter here
                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: response.id,
              })
              .catch(({ error }) => {
                console.warn(error.message);
              });
          } else {
            throw Error;
          }
        })
        .catch(() => {
          toast.error(t('common:anerroroccurred'));
          setLoading(false);
        });
    });

    // Redirect to Checkout.
  }

  async function submitOrder() {
    setLoading(true);

    const calcprice =
      subtotal + (provider.free_delivery_from && subtotal >= provider.free_delivery_from ? 0 : deliveryPrice);

    const constructedOrder: Order = {
      providerId: provider.uid,
      clientId: user.uid,
      client_email: user.email ?? null,
      client_anonymous: user.isAnonymous,
      providerName: provider.name,
      totalprice:
        type === Type.PICKUP
          ? subtotal
          : payment === Payment.CREDIT
            ? calcprice + provider.creditcard_fee * calcprice
            : calcprice,
      pickup: type === Type.PICKUP,
      additional_content: '',
      menuItems: menuItems,
      date: serverTimestamp(),
      status: payment === Payment.CREDIT ? 5 : 0, // 5 stands for unpaid and will later be transformed to 0
      payment: payment,
      delivery_price: type === Type.PICKUP ? 0 : (deliveryPrice ?? 0),
      language: locale,
      source: `bringmos.com NextJS ${version}`,
      last_modified: serverTimestamp(),
      provider_image: provider.picURL || '',
      ...client,
    };

    if (targetDate) {
      constructedOrder.order_target_date = Timestamp.fromDate(targetDate);
    }

    if (payment === Payment.CREDIT) {
      return handleCheckout(constructedOrder)
        .then(() => {
          logEcoommercePurchase(constructedOrder);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    } else {
      return sendOrder(constructedOrder)
        .then(({ id }) => {
          logEcoommercePurchase(constructedOrder);

          if (user.isAnonymous) {
            return saveClient(user.uid, client)
              .then(() => {
                return router.push(`/order/${id}`);
              })
              .catch((error) => {
                console.error(error);
                toast.error(t('common:anerroroccurred'));
                Sentry.captureException(error);
                return router.push(`/order/${id}`);
              });
          } else {
            return router.push(`/order/${id}`);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(t('common:anerroroccurred'));
          Sentry.captureException(error);
        });
    }
  }

  const calcprice = subtotal + (provider.free_delivery_from && subtotal >= provider.free_delivery_from ? 0 : deliveryPrice);

  const disabledCheck =
    disabled ||
    !provider.store_acceptOrders ||
    loading ||
    !payment ||
    !type ||
    (provider.min_order_price ? subtotal < provider.min_order_price : false) ||
    (payment == Payment.CREDIT && provider.creditcard_min_price && subtotal < provider.creditcard_min_price) ||
    menuItems.length < 1 ||
    clientDataInvalid;

  return (
    <>
      <button
        className={`p-4 transition-all flex items-center justify-center bg-green-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-green-500 active:ring-2 active:ring-offset-2 active:ring-offset-white active:ring-green-500 text-green-900 w-full rounded-md shadow disabled:pointer-events-none disabled:bg-black disabled:text-black disabled:text-opacity-40 disabled:bg-opacity-10 dark:disabled:bg-white dark:disabled:text-black dark:disabled:text-opacity-40 dark:disabled:bg-opacity-10`}
        type="submit"
        disabled={disabledCheck}
        onClick={submitOrder}
      >
        <span className="mr-4">
          {payment === Payment.CREDIT
            ? `${t('pay')} ${formatAmountForDisplay(
                type === Type.PICKUP
                  ? subtotal
                  : payment === Payment.CREDIT
                    ? subtotal + deliveryPrice + provider.creditcard_fee * calcprice
                    : calcprice,
                'eur',
              )}`
            : `${t('submitorder')}`}
        </span>
        <Spinner spinnerClasses={'text-gray-900'} loading={loading} />
      </button>

      <Toaster />
    </>
  );
};

export default CheckoutForm;
