
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { collection, doc, query as firestoreQuery, getDocs, limit, orderBy, where } from 'firebase/firestore';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

import { useTheme } from 'next-themes';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ProviderStepper from '../components/ProviderStepper';
import extrasStore from '../lib/extras';
import { firestore, menuItemToJSON, providerToJSON } from '../lib/firebase';
import ingredientsStore from '../lib/ingredients';
import useInterval from '../lib/interval';
import orderStore from '../lib/order';
import { Provider } from '../types/src';
import { computeColors, themeToPalette } from '../utils/colors';
import { isShopOpen } from '../utils/time';

function checkOpenState(provider: Provider): boolean {
  const isOpen = isShopOpen(provider);
  return isOpen;
}

function ProviderPage(props) {
  const { t } = useTranslation('provider');
  const clear = orderStore((state: any) => state.clear);
  const clearIngs = ingredientsStore((state: any) => state.clear);
  const clearExtras = extrasStore((state: any) => state.clear);

  const [shopOpen, setShopOpen] = useState<boolean>(false);

  const { resolvedTheme } = useTheme();
  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    const isOpen = checkOpenState(props.provider);
    setShopOpen(isOpen);
    clear();
    clearIngs();
    clearExtras();

    const palette = themeToPalette(props.provider.theme);
    computeColors(palette, resolvedTheme === 'dark', 'providerthemewrapper');

    let host;
    if (typeof window !== 'undefined') {
      host = window.location.host;
    }

    const isBringmos = host.includes('bringmos');
    if (!isBringmos) {
      // Search for the element with id 'page-title' and replace its text with 'hello'
      const pageTitleElement = document.getElementById('page-title');
      if (pageTitleElement && props.provider.name) {
        pageTitleElement.textContent = props.provider.name;
      }

      // Search for the element with id 'business-link' and hide it
      const linkElement = document.getElementById('business-link');
      if (linkElement && props.provider.name) {
        linkElement.remove();
      }
    }
  }, []);

  useInterval(() => {
    const isOpen = checkOpenState(props.provider);
    setShopOpen(isOpen);
  }, 60000);

  useEffect(() => {
    const palette = themeToPalette(props.provider.theme);
    computeColors(palette, resolvedTheme === 'dark', 'providerthemewrapper');
  }, [resolvedTheme]);

  return (
    <>
      <Head>
        <title>{props.provider.name}</title>
        {props.provider.description && locale && props.provider.description[locale] && (
          <meta name="description" content={props.provider.description[locale]} />
        )}
      </Head>
      <div id="providerthemewrapper" className="flex flex-col h-full">
        {props.provider ? (
          <>
            <ProviderStepper
              provider={props.provider}
              menuItems={props.menuItems}
              customMenuItems={props.customMenuItems}
              locale={props.locale}
              isOpen={shopOpen}
            ></ProviderStepper>
          </>
        ) : (
          <div>{t('noproviderfound')}</div>
        )}
      </div>
    </>
  );
}

 async function _getServerSideProps({ res, query, locale }) {
  res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=119');

  const { slug: link } = query;

  const providerCollection = collection(firestore, 'Providers');

  const providerQuery = firestoreQuery(providerCollection, where('link', '==', link), limit(1));

  const providerDocs = (await getDocs(providerQuery)).docs.map(providerToJSON);
  const provider = providerDocs[0];

  const menuitemCol = collection(firestore, 'MenuItems');

  if (provider) {
    const menuItemQuery = firestoreQuery(
      menuitemCol,
      where('providerId', '==', provider.uid),
      where('available', '==', true),
      orderBy('category_order', 'asc'),
      orderBy('category_code', 'asc'),
      orderBy('subcategories.' + locale, 'asc'),
      orderBy('names.' + locale, 'asc'),
    );

    // TODO: remove after release of combo items

    // custom
    const provDoc = doc(providerCollection, provider.uid);
    const customCol = collection(provDoc, 'MenuItemPresets');

    return Promise.all([getDocs(menuItemQuery), getDocs(customCol)]).then(([menuItemResp, customMenuItemsResp]) => {
      const menuItems = (menuItemResp.docs.map(menuItemToJSON) || []).filter(
        (m) => m.availableForBusinessOrders !== 'onlyBusiness',
      );
      const customMenuItems = customMenuItemsResp.docs.map(menuItemToJSON) || [];

      return {
        props: {
          provider,
          menuItems: menuItems,
          customMenuItems: customMenuItems,
          locale,
          link,
        },
      };
    });
  } else {
    return {
      notFound: true,
    };
  }
}

export default ProviderPage;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/[slug]',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  