import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import useTranslation from 'next-translate/useTranslation';
import { BusinessHours } from '../types/src';

export default function ProviderBusinessHours({ businessHours, isOpen }: { businessHours: BusinessHours; isOpen: boolean }) {
  const { t } = useTranslation('provider');
  const now = new Date();
  const day = now.getDay();
  let dayOfWeek;
  if (day === 0) {
    dayOfWeek = 7;
  } else {
    dayOfWeek = day;
  }

  return (
    businessHours &&
    businessHours.active && (
      <div
        className={`p-4 rounded-lg flex flex-col ${
          isOpen ? 'bg-secondary-container text-light-on-secondary-container ' : 'bg-error-container text-on-error-container'
        }`}
      >
        <span
          className={`text-xs text-center font-semibold border-b pb-2 mb-2 ${isOpen ? 'border-white/20' : 'border-error'}`}
        >
          {isOpen ? t('business_hours.open') : t('business_hours.closed')}
        </span>
        <div key={'zone'} className={`flex flex-row items-center justify-between text-sm font-semibold`}>
          <p>{t(`business_hours.${dayOfWeek}`)}</p>
          <p className={`flex flex-col justify-end text-right`}>
            {businessHours && businessHours[day] ? (
              businessHours[day].length ? (
                businessHours[day].map((day, j) => <span key={`zone${j}`}>{`${day.from} - ${day.to}`}</span>)
              ) : (
                <span>{t('business_hours.fullday')}</span>
              )
            ) : (
              <span>{t('business_hours.closed')}</span>
            )}
          </p>
        </div>
      </div>
    )
  );
}

function MobileWrapper({ children }: { children: any }) {
  const { t } = useTranslation('provider');

  return (
    <div className="bg-white bg-opacity-10 backdrop-overlay rounded-lg md:hidden">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="outline-none flex justify-between w-full px-4 py-4 text-sm font-medium text-left transition-all focus:outline-none focus-visible:ring focus-visible:ring-bringmos focus-visible:ring-opacity-75">
              <p className="text-white text-opacity-70">{t('business_hours.title')}</p>

              <ChevronUpIcon className={`transition-all ${open ? '' : 'transform rotate-180'} w-5 h-5 text-primary `} />
            </Disclosure.Button>
            <Disclosure.Panel className="pb-2 text-sm text-gray-500 max-h-80 overflow-y-auto">{children}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
