import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { Ingredient, LanguageMap, MenuItem, SupportedCategories } from '../types/src';

export type IngredientReturnType = {
  added_ingredients?: { [ingId: string]: LanguageMap };
  removed_ingredients?: { [ingId: string]: LanguageMap };
  ingredients: Ingredient[];
  ingredientIds?: string[];
  multi_ingredients: LanguageMap;
};

type Props = {
  loadedMenuItems: MenuItem[];
  locale: string;
  emitItems: (pizza: MenuItem[]) => void;
  count?: number;
};

export default function MenuItemSelect({ loadedMenuItems, locale, emitItems, count }: Props) {
  const { t } = useTranslation('provider');
  const [selectedItems, setSelectedItems] = useState<MenuItem[]>([]);
  const categories = SupportedCategories[locale];

  useEffect(() => {
    emitItems(selectedItems);
  }, [selectedItems]);

  function setItems(menuItem: MenuItem) {
    if (!count) {
      setSelectedItems([menuItem]);
    } else {
      if (selectedCount(menuItem) < count) {
        setSelectedItems([menuItem, ...selectedItems.slice(0, count - 1)]);
      } else {
        const index = selectedItems.findIndex((m) => m.uid === menuItem.uid);
        if (index > -1) {
          setSelectedItems([...selectedItems.slice(index, count - 1)]);
        } else {
          setSelectedItems([menuItem, ...selectedItems.slice(0, count - 1)]);
        }
      }
    }
  }

  const selectedCount = (menuItem): number => selectedItems.filter((m) => m.uid === menuItem.uid).length ?? 0;

  return (
    <div className="flex flex-col items-center pb-4">
      <div className="w-full grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 xl:grid-cols-7 gap-4">
        {loadedMenuItems &&
          loadedMenuItems.map((menuItem, i) => (
            <button
              key={menuItem.uid + i}
              onClick={() => setItems(menuItem)}
              className={`outline-none focus:outline-none transition-all flex flex-col text-sm justify-between items-start h-20 relative overflow-hidden p-2 rounded-md shadow ${
                selectedItems.findIndex((m) => m.uid === menuItem.uid) > -1
                  ? 'bg-primary text-on-primary'
                  : 'bg-surface-container-highest text-on-surface'
              }`}
            >
              <div className="h-16 w-16 absolute -bottom-4 -right-4 display flex items-center justify-center">
                {menuItem.imageUrl ? (
                  <Image height={64} width={64} src={menuItem.imageUrl} alt="Pizza" />
                ) : (
                  <Image
                    height={64}
                    width={64}
                    className={``}
                    src={`/placeholders/${categories.find((c) => c.code == menuItem.category_code).avatar}`}
                    alt={menuItem.names[locale] || 'Item pic'}
                  />
                )}
              </div>
              <span className="z-10 text-ellipsis font-semibold">{menuItem.names && menuItem.names[locale]}</span>

              {count && selectedCount(menuItem) && selectedCount(menuItem) > 0 ? (
                <span className="absolute bottom-2 left-2">{selectedCount(menuItem)}x</span>
              ) : null}
            </button>
          ))}
      </div>
    </div>
  );
}
