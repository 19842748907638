import useTranslation from 'next-translate/useTranslation';
import React, { Fragment, useEffect, useState } from 'react';

import { Transition } from '@headlessui/react';
import orderStore from '../lib/order';
import { MenuItem, Provider } from '../types/src';
import { Button } from './Button';
import CategoryList from './CategoryList';
import CompleteOrder from './CompleteOrder';
import ProviderHeader from './ProviderHeader';
import ProviderMenu from './ProviderMenu';
import SideCart from './SideCart';

export enum Steps {
  MENU = 'menu',
  COMPLETE = 'complete',
}

type Props = {
  provider: Provider;
  menuItems: MenuItem[];
  customMenuItems: MenuItem[];
  locale: string;
  isOpen: boolean;
};

export default function ProviderStepper({ provider, menuItems, customMenuItems, locale, isOpen }: Props) {
  const { t } = useTranslation('provider');

  const [activeStep, setActiveStep] = React.useState(Steps.MENU);

  const menuOnly = !provider.features?.orders;

  const handlePrevious = () => {
    if (activeStep === Steps.COMPLETE) {
      setActiveStep(Steps.MENU);
    }
  };

  const handleNext = () => {
    if (activeStep === Steps.MENU) {
      setActiveStep(Steps.COMPLETE);
    }
  };

  const subtotal = orderStore((state) => (state as any).subtotal);
  const [animateButton, setAnimateButton] = useState(true);

  useEffect(() => {
    setAnimateButton(false);
    const timer = setTimeout(() => setAnimateButton(true), 100); // Trigger re-render with transition
    return () => clearTimeout(timer);
  }, [subtotal]);

  const executeScroll = () => {
    if (window) {
      const myRef = document.getElementById('complete-top-title');

      const yOffset = -100;
      const y = myRef?.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' }); // For Chrome, Firefox, IE and Opera});
    }
  };

  useEffect(() => {
    if (activeStep === Steps.COMPLETE) {
      executeScroll();
    }
  }, [activeStep]);

  return (
    <>
      <ProviderHeader activeStep={activeStep} provider={provider} locale={locale} isOpen={isOpen}></ProviderHeader>
      {activeStep === Steps.COMPLETE && (
        <div className="md:hidden container px-8 pt-20 mx-auto max-w-6xl">
          <SideCart provider={provider} handleNext={handleNext} handlePrevious={handlePrevious} step={activeStep} />
        </div>
      )}

      {menuOnly ? (
        <div className="max-w-[90rem] px-8 w-full mx-auto pt-4 flex-1 dark:text-white">
          <div className="relative pb-20">
            <div className="flex flex-col border-t border-outline-variant pt-4">
              <CategoryList menuItems={menuItems} locale={locale}></CategoryList>

              <ProviderMenu
                provider={provider}
                menuItems={menuItems}
                customMenuItems={customMenuItems}
                locale={locale}
                disabled={menuOnly || !isOpen}
                menuOnly={menuOnly}
              ></ProviderMenu>
              <p className="mt-4 py-4 text-xs text-gray-500">{t('disclaimer')}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="max-w-[90rem] px-8 w-full mx-auto pt-4 flex-1 dark:text-white">
          <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 pb-20">
            <div
              className={`${activeStep === Steps.MENU ? 'row-span-full md:col-span-2 lg:col-span-2 col-start-1' : 'hidden'}`}
            >
              <div className="flex flex-col border-t border-outline-variant pt-4">
                <CategoryList menuItems={menuItems} locale={locale}></CategoryList>

                <ProviderMenu
                  provider={provider}
                  menuItems={menuItems}
                  customMenuItems={customMenuItems}
                  locale={locale}
                  disabled={!isOpen}
                ></ProviderMenu>
                <p className="mt-4 py-4 text-xs text-gray-500">{t('disclaimer')}</p>
              </div>
            </div>

            <div
              className={`hidden md:block ${
                activeStep === Steps.MENU
                  ? 'row-span-full w-full col-start-3 col-span-1'
                  : 'row-span-full w-full col-start-1 col-span-1'
              } flex-none`}
            >
              <div className="mt-8 px-4 lg:px-8 py-8 sticky md:max-w-[300px] lg:max-w-none top-20 rounded-xl bottom-0 max-h-fullwheader bg-surface-variant text-light-on-surface-variant">
                <SideCart provider={provider} handleNext={handleNext} handlePrevious={handlePrevious} step={activeStep} />
              </div>
            </div>

            <div
              className={`${
                activeStep === Steps.MENU
                  ? 'hidden'
                  : 'row-span-full w-full col-span-1 md:col-span-2 col-start-1 md:col-start-2'
              }`}
            >
              <CompleteOrder
                provider={provider}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
                disabled={!isOpen}
              />
            </div>
          </div>
          {activeStep === Steps.MENU && (
            <Button
              onClick={handleNext}
              disabled={
                !isOpen ||
                !provider.store_acceptOrders ||
                menuItems.length <= 0 ||
                (provider.min_order_price && subtotal < provider.min_order_price)
              }
              className="md:hidden z-50 shadow fixed bottom-8 inset-x-0 mx-8 py-2 rounded-lg bg-primary text-on-primary flex items-center justify-between px-4"
            >
              <div className="w-full flex flex-row items-center justify-between">
                <div className="flex flex-row items-center flex-1">
                  <i className="mr-2 text-3xl las la-shopping-cart"></i>
                  <h2 className="text-xl">{t('cart')}</h2>
                </div>
                <Transition
                  show={animateButton}
                  as={Fragment}
                  enter="transition ease-out duration-500 transform"
                  enterFrom="opacity-0 -translate-y-10 scale-75"
                  enterTo="opacity-100 translate-y-0 scale-100"
                  leave="transition ease-in duration-500 transform"
                  leaveFrom="opacity-100 translate-y-0 scale-100"
                  leaveTo="opacity-0 -translate-y-10 scale-75"
                >
                  <span>{subtotal ? subtotal.toFixed(2) : (0).toFixed(2)}€</span>
                </Transition>
              </div>
            </Button>
          )}
        </div>
      )}
    </>
  );
}
