import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import ProviderBusinessHours from '../components/BusinessHours';
import { Provider } from '../types/src';
import { Steps } from './ProviderStepper';

type Props = {
  provider: Provider;
  activeStep: Steps;
  locale: string;
  isOpen: boolean;
};

const ReactTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false,
});

export default function ProviderHeader({ activeStep, provider, locale, isOpen }: Props) {
  const { t } = useTranslation('common');

  return (
    <div className={`relative w-full max-w-[90rem] mx-auto px-8 ${activeStep === Steps.COMPLETE ? 'hidden' : ''}`}>
      <div className={`relative transition-all ease-in duration-100`}>
        <div className="w-full pt-[88px] top-0 z-20 grid grid-cols-1 md:grid-cols-4 xl:grid-cols-3 gap-10 pb-4">
          <div className="col-span-2 flex flex-col justify-center pb-0 container max-w-screen-xl mx-auto">
            <div className="flex flex-row md:mb-4">
              {provider.picURL && (
                <Image
                  className="rounded-full w-24 h-24 mr-4"
                  objectFit="cover"
                  width={64}
                  height={64}
                  src={provider.picURL}
                  alt={provider.name + 'Logo'}
                />
              )}
              <div>
                <h1 className="font-semibold opacity-80 text-4xl md:text-5xl mb-4">{provider.name}</h1>
                {provider.type && provider.type[locale] ? (
                  <p className="block m-0 uppercase text-sm font-semibold">{provider?.type[locale]}</p>
                ) : null}
                {provider.phone ? <p className="block m-0 uppercase text-sm font-semibold">Tel: {provider?.phone}</p> : null}

                {provider?.payment_methods?.credit == true ? (
                  <div className="flex h-6 w-full my-4">
                    <img className="object-center mr-2" height="24px" src="/cards/visa.svg" alt="Visa" />
                    <img className="object-center" height="24px" src="/cards/mastercard.svg" alt="Mastercard" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="grid col-span-2 xl:col-span-1 gap-4 md:self-start">
            <div className="w-full grid grid-cols-3 gap-4">
              {provider.min_order_price ? (
                <div
                  data-tip={t('minorderprice')}
                  className="flex flex-col p-1 items-center bg-surface-variant text-light-on-surface-variant dark:bg-gray-700 rounded-lg font-semibold"
                >
                  <i className="text-2xl las la-money-bill-wave"></i>
                  <p className="">{provider.min_order_price} €</p>
                </div>
              ) : null}

              {provider.deliveryAvailable && provider.delivery_time ? (
                <div
                  data-tip={t('waitingtime')}
                  className="flex flex-col p-1 items-center bg-surface-variant text-light-on-surface-variant dark:bg-gray-700 rounded-lg font-semibold"
                >
                  <i className="text-2xl las la-clock"></i>
                  <p className=""> {provider.delivery_time}</p>
                </div>
              ) : null}

              {provider.delivery_costs ? (
                <div
                  data-tip={t('deliverycosts')}
                  className="flex flex-col p-1 items-center bg-surface-variant text-light-on-surface-variant dark:bg-gray-700 rounded-lg font-semibold"
                >
                  <i className="text-2xl las la-shipping-fast"></i>
                  <p className=""> {provider.delivery_costs}</p>
                </div>
              ) : null}

              <ReactTooltip effect="solid" />
            </div>
            {provider.businessHours && (
              <ProviderBusinessHours isOpen={isOpen} businessHours={provider.businessHours}></ProviderBusinessHours>
            )}
            <ProviderLocations provider={provider} locale={locale} />
          </div>
        </div>
      </div>
    </div>
  );
}

function ProviderLocations({ provider, locale }: { provider: Provider; locale: string }) {
  const { t } = useTranslation('provider');

  return provider.delivery_locations ? (
    <div className="z-20 relative bg-surface-variant text-light-on-surface-variant rounded-lg">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="outline-none flex justify-between w-full px-4 py-4 text-sm font-medium text-left transition-all focus:outline-none focus-visible:ring focus-visible:ring-bringmos focus-visible:ring-opacity-75">
              <p className="font-semibold">{t('provider:delivery_locations')}</p>

              <ChevronUpIcon className={`transition-all ${open ? '' : 'transform rotate-180'} w-5 h-5 text-primary `} />
            </Disclosure.Button>
            <Disclosure.Panel className="shadow rounded-lg bg-surface-variant text-light-on-surface-variant absolute top-14 inset-x-0 py-4 text-sm dark:text-gray-200 max-h-80 overflow-y-auto">
              {provider.delivery_locations &&
                provider.delivery_locations.map((location, i) => (
                  <div key={i} className="flex items-center mx-4 py-1">
                    <span className="text-sm">{location.names && location.names[locale]}</span>
                    <span className="flex-1"></span>
                    <span>{location.price ? location.price.toFixed(2) : 0}€</span>
                  </div>
                ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  ) : null;
}
