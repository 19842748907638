import { signInAnonymously } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { auth } from '../lib/firebase';

import extrasStore from '../lib/extras';
import { extraToJSON, firestore, ingredientToJSON } from '../lib/firebase';
import { getCategoryFromCode } from '../lib/helper';
import ingredientsStore from '../lib/ingredients';
import { MenuItem, Provider, ProviderMenuPreferences } from '../types/src';
import CustomMenuItemComponent from './CustomMenuItem';
import MenuItemComponent from './MenuItem';
import JsonLdMeta from './meta/JsonLdMeta';
import OpenGraphMeta from './meta/OpenGraphMeta';

type Props = {
  provider: Provider;
  menuItems: MenuItem[];
  customMenuItems: MenuItem[];
  locale: string;
  disabled: boolean;
  menuOnly?: boolean;
};

function ProviderMenu(props: Props) {
  const { locale } = useRouter();
  const [expandedMenuItemId, setExpandedMenuItemId] = useState<string>('');
  const [ingredientsForSlicesDisabled, setIngredientsForSlicesDisabled] = useState<boolean>(false);

  const ingredients = ingredientsStore((state) => (state as any).ingredients);
  const overrideIngredients = ingredientsStore((state: any) => state.overrideIngredients);

  const extras = extrasStore((state) => (state as any).extras);
  const overrideExtras = extrasStore((state: any) => state.overrideExtras);

  useEffect(() => {
    if (!auth.currentUser) {
      signInAnonymously(auth).then(() => {
        console.log('signed in anonymously');
        getMenuPreferences();
      });
    } else {
      getMenuPreferences();
    }
  }, []);

  function toggle(id: string, isCustom: boolean = false) {
    if (expandedMenuItemId !== id) {
      const categoryCode = isCustom
        ? props.customMenuItems.find((mI) => mI.uid === id).category_code
        : props.menuItems.find((mI) => mI.uid === id).category_code;
      getExtras(props.provider.uid, categoryCode);

      const shouldLoadIngredients =
        (categoryCode === 'pizza' && props.provider.customItemsAvailable) || categoryCode === 'pizzamammut';
      if (shouldLoadIngredients) {
        getIngredients(props.provider.uid);
      }
    }

    const valueToSet = expandedMenuItemId === id ? '' : id;
    setExpandedMenuItemId(valueToSet);
  }

  async function getExtras(providerId: string, category_code: string): Promise<void> {
    if (extras[category_code]) {
      console.log('extras from cache');
    } else {
      const extrasItemQuery = query(
        collection(firestore, 'Extras'),
        where('providerId', '==', providerId),
        where('category_code', '==', category_code),
        where('available', '==', true),
        orderBy('groups.' + locale, 'desc'),
        orderBy('is_multiple', 'desc'),
      );

      const menuItemExtras = (await getDocs(extrasItemQuery)).docs.map(extraToJSON) || [];
      const e = Object.assign({}, extras);
      e[category_code] = menuItemExtras as any;
      overrideExtras(e);
    }
  }

  async function getIngredients(providerId: string): Promise<void> {
    if (ingredients && ingredients.length) {
      console.log('ings from cache');
    } else {
      const ingsItemQuery = query(
        collection(firestore, 'Ingredients'),
        where('providerId', '==', providerId),
        where('available', '==', true),
        orderBy('names.' + locale, 'asc'),
      );

      const ingredients = (await getDocs(ingsItemQuery)).docs.map(ingredientToJSON) || [];

      overrideIngredients(ingredients);
    }
  }

  function getMenuPreferences(): Promise<void> {
    const providerDoc = doc(collection(firestore, 'Providers'), props.provider.uid);
    const preferencesCollection = collection(providerDoc, 'Preferences');
    const menuPrefDoc = doc(preferencesCollection, 'menu');

    return getDoc(menuPrefDoc).then((doc) => {
      const menuPref = doc.data() as ProviderMenuPreferences;
      setIngredientsForSlicesDisabled(!!menuPref?.disable_slices_ingredients);
    });
  }

  const jsonLDProps = {
    name: props.provider.name,
    phone: props.provider.phone,
    url: props.provider.link,
    address: props.provider.address && locale ? props.provider.address[locale] : '',
    zip: props.provider.zip,
    town: props.provider.town && locale ? props.provider.town[locale] : '',
    ratingValue: props.provider.rating?.avg ?? 0,
    ratingCount: props.provider.rating?.count ?? 0,
    image: props.provider.seoImageURL,
    description: props.provider.description && locale ? props.provider.description[locale] : '',
    menuItems: props.menuItems,
    locale,
  };

  const { t } = useTranslation('provider');

  return props.menuItems ? (
    <>
      <OpenGraphMeta
        title={props.provider.name}
        description={props.provider.description && locale ? props.provider.description[locale] : ''}
        link={`/${props.provider.link}`}
      />
      <JsonLdMeta {...jsonLDProps} />
      <Toaster />
      <div
        className={`grid ${props.menuOnly ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10' : 'grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4'}`}
      >
        {!props.menuOnly &&
          props.customMenuItems.map((customMenuItem, i) => (
            <React.Fragment key={customMenuItem.uid + i}>
              {customMenuItem.custom && i ? (
                <span className="font-chau text-3xl lg:col-span-2 p-4">
                  CUSTOM <i className="icon las la-tools"></i>{' '}
                </span>
              ) : null}
              {i == 0 ? (
                <span id={customMenuItem.category_code} className="font-chau text-4xl lg:col-span-2 p-4">
                  {t('custommenuitems')}
                </span>
              ) : null}
              <CustomMenuItemComponent
                provider={props.provider}
                toggle={(id) => toggle(id, true)}
                expanded={customMenuItem.uid === expandedMenuItemId}
                customMenuItem={customMenuItem}
                locale={props.locale}
                disabled={props.disabled}
              ></CustomMenuItemComponent>
            </React.Fragment>
          ))}

        {props.menuItems.map((menuItem, i) => (
          <React.Fragment key={menuItem.uid + i}>
            {menuItem.custom && i ? (
              <span className={`font-chau text-3xl p-4 ${props.menuOnly ? 'md:col-span-2 lg:col-span-3' : 'lg:col-span-2'}`}>
                CUSTOM <i className="icon las la-tools"></i>{' '}
              </span>
            ) : null}

            {!menuItem.custom && (i == 0 || (i != 0 && menuItem.category_code != props.menuItems[i - 1].category_code)) ? (
              <span
                id={menuItem.category_code}
                className={`font-chau text-4xl p-4 pb-0 pt-8 text-on-background ${props.menuOnly ? 'md:col-span-2 lg:col-span-3' : 'lg:col-span-2'}`}
              >
                {getCategoryFromCode(menuItem?.category_code, props.locale)}
              </span>
            ) : null}

            {!menuItem.custom &&
            menuItem.subcategories[props.locale] &&
            (i == 0 || menuItem.subcategories[props.locale] !== props.menuItems[i - 1].subcategories[props.locale]) ? (
              <span
                id={menuItem.subcategories[props.locale]}
                className={`font-chau text-2xl px-4 text-on-surface-variant ${props.menuOnly ? 'md:col-span-2 lg:col-span-3' : 'lg:col-span-2'}`}
              >
                {menuItem.subcategories[props.locale]}{' '}
              </span>
            ) : null}

            <MenuItemComponent
              provider={props.provider}
              toggle={(id) => toggle(id)}
              expanded={menuItem.uid === expandedMenuItemId}
              menuItem={menuItem}
              loadedMenuItems={props.menuItems}
              locale={props.locale}
              disabled={props.disabled}
              ingredientsForSlicesDisabled={ingredientsForSlicesDisabled}
              menuOnly={props.menuOnly}
            ></MenuItemComponent>
          </React.Fragment>
        ))}
      </div>
    </>
  ) : null;
}

export default ProviderMenu;
