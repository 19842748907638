import { MenuItem } from '../types/src';
import { isInInterval } from './time';

export const isItemLimited = (menuItem: MenuItem) => {
  const itemIsAvailable = menuItem.time_limited?.active && isAvailable(menuItem);
  const itemLimited = menuItem.time_limited && !itemIsAvailable;
  return itemLimited;
};

function isAvailable(item: MenuItem): boolean {
  const now = new Date();
  if (item.time_limited) {
    const day = now.getDay();
    let dayOfWeek;
    if (day === 0) {
      dayOfWeek = 7;
    } else {
      dayOfWeek = day;
    }

    if (item.time_limited.daily?.length) {
      return item.time_limited.daily.findIndex((interval) => isInInterval(now, interval.from, interval.to)) > -1;
    } else if (item.time_limited.weekly && dayOfWeek && item.time_limited.weekly[dayOfWeek]) {
      return (
        item.time_limited.weekly[dayOfWeek].findIndex((interval) => isInInterval(now, interval.from, interval.to)) > -1 ||
        item.time_limited.weekly[dayOfWeek]?.length === 0
      );
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function nextAvailable(item: MenuItem, t: any): string {
  const now = new Date();
  if (item.time_limited) {
    const day = now.getDay();
    let dayOfWeek;
    if (day === 0) {
      dayOfWeek = 7;
    } else {
      dayOfWeek = day;
    }

    if (item.time_limited.daily?.length) {
      return item.time_limited.daily
        .map((interval) => `${t('today')}: ${interval.from} - ${interval.to}`)
        .join(', ')
        .replace(/, ([^,]*)$/, ` ${t('provider:and')} $1`);
    } else if (item.time_limited.weekly && dayOfWeek && item.time_limited.weekly[dayOfWeek]) {
      return item.time_limited.weekly[dayOfWeek]
        .map((interval) => `${t('today')}: ${interval.from} - ${interval.to}`)
        .join(', ')
        .replace(/, ([^,]*)$/, ` ${t('provider:and')} $1`);
    } else {
      return '';
    }
  } else {
    return '';
  }
}
