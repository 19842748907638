import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import ingredientsStore from '../lib/ingredients';
import { Ingredient, LanguageMap } from '../types/src';
import { mapIngredientToLocale } from '../utils/language-helper';

export type IngredientReturnType = {
  added_ingredients?: { [ingId: string]: LanguageMap };
  removed_ingredients?: { [ingId: string]: LanguageMap };
  ingredients: Ingredient[];
  ingredientIds?: string[];
  multi_ingredients: LanguageMap;
};

type Props = {
  hidePreview: boolean;
  hidePrice: boolean;
  selectedIngredients: Ingredient[];
  locale: string;
  emitIngredients: (ingredients: IngredientReturnType) => void;
};

function sortArray(ings: Ingredient[]): Ingredient[] {
  return ings.sort((a, b) => (a.names.de == 'Tomatensoße' ? -1 : b.names.de == 'Tomatensoße' ? 1 : 0));
}

export default function IngredientSelect({
  selectedIngredients,
  locale,
  emitIngredients,
  hidePreview = false,
  hidePrice = false,
}: Props) {
  const { t } = useTranslation('provider');
  const [selectedIngs, setSelectedIngs] = useState(selectedIngredients);

  const loadedIngredients = ingredientsStore((state) => (state as any).ingredients);

  useEffect(() => {
    setSelectedIngs(selectedIngredients);
  }, [loadedIngredients]);

  const added = findAdded(selectedIngs);
  const removed = findRemoved(selectedIngs);

  useEffect(() => {
    const added_ingredients = added.reduce((map, obj) => {
      map[obj.uid] = obj.names;
      return map;
    }, {});

    const removed_ingredients = removed.reduce((map, obj) => {
      map[obj.uid] = obj.names;
      return map;
    }, {});

    emitIngredients({
      multi_ingredients: {
        de: mapIngredientToLocale(selectedIngs, 'de'),
        it: mapIngredientToLocale(selectedIngs, 'it'),
        en: mapIngredientToLocale(selectedIngs, 'en'),
      },
      ingredients: selectedIngs,
      ingredientIds: selectedIngs.map((ing) => ing.uid),
      added_ingredients,
      removed_ingredients,
    });
  }, [selectedIngs]);

  function toggleIngredient(ingredient: Ingredient) {
    const copy = Object.assign([], selectedIngs);
    const index = copy.findIndex((ing) => ing === ingredient);
    if (index > -1) {
      copy.splice(index, 1);
    } else {
      copy.push(ingredient);
    }

    setSelectedIngs(copy);
  }

  function findAdded(selectedIngs: Ingredient[]): Ingredient[] {
    return selectedIngs.filter((ing) => !selectedIngredients.includes(ing));
  }

  function findRemoved(selectedIngs: Ingredient[]): Ingredient[] {
    return selectedIngredients.filter((ing) => !selectedIngs.includes(ing));
  }

  const sortedArray = selectedIngs.find((ing) => ing.names.de == 'Tomatensoße') ? sortArray(selectedIngs) : selectedIngs;

  return (
    <div className="flex flex-col items-center pb-4">
      {!hidePreview ? (
        <>
          <div className="flex flex-col md:flex-row w-full">
            <div className="flex items-center justify-center pr-4">
              <div className="w-60 h-60 relative my-4">
                <Image width={240} height={240} className="" src="/placeholders/pizza_ground.png" alt="pizza dough" />
                {sortedArray.map(
                  (ing, i) =>
                    ing.image_url && (
                      <div key={`preview:${ing.uid}-${i}`} className="absolute inset-0">
                        <Image width={240} height={240} src={ing.image_url} alt={`ingredient-${i}`} />
                      </div>
                    ),
                )}
              </div>
            </div>

            <div className="flex-1 flex flex-col justify-center my-4">
              {added.length ? (
                <div className="bg-surface-container-high text-on-surface rounded-xl p-2 px-4 my-2">
                  <div className="flex justify-start flex-wrap">
                    <p>
                      {t('provider:with')}:{' '}
                      {added
                        .map((ing) => ing.names[locale])
                        .join(', ')
                        .replace(/, ([^,]*)$/, ` ${t('provider:and')} $1`)}
                    </p>
                  </div>
                </div>
              ) : null}

              {removed.length ? (
                <div className="bg-error-container text-on-error-container rounded-xl p-2 px-4 my-2">
                  <div className="flex justify-start flex-wrap text-red-600 dark:text-red-400">
                    <p>
                      {t('provider:without')}:{' '}
                      {removed
                        .map((ing) => ing.names[locale])
                        .join(', ')
                        .replace(/, ([^,]*)$/, ` ${t('provider:and')} $1`)}{' '}
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : null}

      <div className="w-full grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {loadedIngredients &&
          loadedIngredients.map((ingredient, i) => (
            <button
              key={ingredient.uid + i}
              onClick={() => toggleIngredient(ingredient)}
              className={`relative text-ellipsis overflow-hidden outline-none focus:outline-none transition-all flex flex-col text-sm justify-between items-start h-20 p-2 sm:p-3 rounded-md ${
                selectedIngs.find((i) => i === ingredient)
                  ? 'bg-primary text-on-primary'
                  : 'bg-surface-container-highest text-on-surface'
              }`}
            >
              {ingredient.image_url && (
                <div className="h-16 w-16 absolute -bottom-4 -right-4 display flex items-center justify-center">
                  <Image height={64} width={64} src={ingredient.image_url} alt={'ingredient-' + i} />
                </div>
              )}
              <span className="text-left z-10 whitespace-nowrap font-semibold">
                {ingredient.names && ingredient.names[locale]}
              </span>
              {!hidePrice ? <span className="z-10">{ingredient.price.toFixed(2)}€</span> : null}
            </button>
          ))}
      </div>
    </div>
  );
}
