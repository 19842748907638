import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { COUNTRIES, Country } from '../types/src';

type Props = {
  clientCountry: Country;
  emitCountry: (country: Country) => void;
};

const labelClasses =
  'flex flex-col text-[12px] text-on-surface opacity-60 mb-1 leading-14.5px whitespace-nowrap overflow-hidden text-ellipsis';
const inputClasses =
  'text-left text-ellipsis overflow-hidden whitespace-nowrap transition-all bg-white dark:bg-black bg-opacity-50 dark:bg-opacity-20 w-full text-base placeholder-gray-500 outline-none border border-gray-300 dark:border-gray-600 rounded-md py-2 px-2 focus:border-primary focus:outline-none active:border-primary border border-input-light-border dark:border-input-dark-border hover:border-black hover:dark:border-white';

export default function PhoneCountry({ clientCountry, emitCountry }: Props) {
  const { t } = useTranslation('profile');

  return (
    <div className="mr-4 relative">
      <label className={labelClasses} htmlFor="phonetown">
        {t('form.phonetown')}
      </label>
      <Listbox value={clientCountry} onChange={emitCountry}>
        <ListboxButton className={inputClasses}>
          {clientCountry ? `${clientCountry.name} (${clientCountry?.phoneCode})` : t('unknowncountry')}
        </ListboxButton>
        <ListboxOptions className="z-10 absolute t-14 left-0 rounded-md shadow mt-2 w-60 overflow-x-hidden overflow-y-auto outline-none max-h-[300px]  overflow-scroll bg-surface text-on-surface cursor-pointer text-sm">
          {COUNTRIES.map((country) => (
            <ListboxOption key={country.isoCode} value={country}>
              {({ active, selected }) => (
                <div className={`py-1 px-2 overflow-hidden flex items-center ${active ? 'bg-primary text-on-primary' : ''}`}>
                  {selected && <CheckIcon className="flex-shrink-0 block h-6 w-6 mr-2 text-primary" />}
                  {!selected && <CheckIcon className="flex-shrink-0 block invisible h-6 w-6 mr-2" />}
                  <span className="w-full mr-4 overflow-hidden">{country.name}</span>
                  <span className="flex-grow"></span>
                  <Image
                    height={24}
                    width={28}
                    alt={`flag ${country?.isoCode}`}
                    className="rounded-sm"
                    src={`/flags/${country.isoCode.toLowerCase()}.png`}
                  />
                </div>
              )}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </div>
  );
}
