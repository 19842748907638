import useTranslation from 'next-translate/useTranslation';
import { Control, UseFormRegister } from 'react-hook-form';

import { Country, Provider, User } from '../types/src';
import DeliveryTown from './DeliveryTown';
import PhoneCountry from './PhoneCountry';
import { TextInput } from './TextInput';

type ClientDataForOrderProps = {
  control: Control<User>;
  onlyNameAndPhone?: boolean;
  register: UseFormRegister<any>;
  showSave?: boolean;
  emitSubmit?: any;
  country: Country;
  emitCountry: (country: Country) => void;
  provider: Provider;
  errors: any;
  touchedFields: any;
};

function ClientDataForOrder({
  control,
  country,
  emitSubmit,
  emitCountry,
  onlyNameAndPhone,
  showSave,
  register,
  provider,
  errors,
  touchedFields,
}: ClientDataForOrderProps) {
  const { t } = useTranslation('profile');

  return (
    <form className="flex flex-col w-full" onSubmit={emitSubmit}>
      <h2 className="self-start text-2xl mb-4">{t('title')}</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-4 mb-4">
        <div>
          <TextInput
            id="client_name"
            label={t('form.name')}
            error={errors.client_name && touchedFields.client_name ? t('missingfield') : null}
            type="text"
            {...register('client_name', { required: true })}
          />
        </div>

        <div className="block md:col-span-2">
          <div className="flex">
            <div className="flex-shrink min-w-0">
              <PhoneCountry clientCountry={country} emitCountry={emitCountry} />
            </div>
            <div className="flex-1">
              <TextInput
                id="client_phone"
                label={t('form.phone')}
                error={errors.client_phone && touchedFields.client_phone ? t('missingfield') : null}
                type="text"
                {...register('client_phone')}
              />
            </div>
          </div>
        </div>
      </div>

      {!onlyNameAndPhone ? (
        <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-4">
          <div>
            <TextInput
              id="client_address"
              label={t('form.address')}
              error={errors.client_address && touchedFields.client_address ? t('missingfield') : null}
              type="text"
              {...register('client_address', { required: true })}
            />
          </div>

          <div className="block">
            <DeliveryTown provider={provider} control={control} />
          </div>

          <div>
            <TextInput
              id="client_zip"
              label={t('form.zip')}
              error={errors.client_zip && touchedFields.client_zip ? t('missingfield') : null}
              type="number"
              {...register('client_zip', { required: true })}
            />
          </div>
        </div>
      ) : null}
      {showSave ? (
        <button
          type="submit"
          className="bg-primary rounded-md py-2 px-4 text-white shadow self-end my-4 outline-none focus:outline-none text-sm"
        >
          {t('common:save')}
        </button>
      ) : null}
    </form>
  );
}

export default ClientDataForOrder;
