import useTranslation from 'next-translate/useTranslation';

import orderStore from '../lib/order';
import { Provider } from '../types/src';
import { Button, ButtonVariants } from './Button';
import { Steps } from './ProviderStepper';
import SideCartItems from './SideCartItems';

type Props = {
  handleNext: () => void;
  handlePrevious: () => void;
  provider: Provider;
  step: Steps;
};

export default function SideCart({ handleNext, handlePrevious, provider, step }: Props) {
  const { t } = useTranslation('provider');

  const menuItems = orderStore((state) => (state as any).menuItems);
  const subtotal = orderStore((state) => (state as any).subtotal);

  return (
    <div className="flex flex-col">
      <div className="overflow-y-auto flex-1">
        <div className="flex flex-row items-center border-b border-outline-variant pb-4">
          <i className="mr-2 text-3xl las la-shopping-cart"></i>
          <h2 className="text-xl">{t('cart')}</h2>
        </div>

        <div>
          <SideCartItems menuItems={menuItems} extras></SideCartItems>
        </div>
      </div>

      <div className="block text-sm text-gray-500 dark:text-gray-400 border-b border-outline-variant">
        <div className="flex py-2 text-black dark:text-white items-center">
          <p className="flex-grow font-semibold">{t('subtotal')}</p>
          <p className="text-base">{subtotal ? subtotal.toFixed(2) : (0).toFixed(2)}€</p>
        </div>

        {provider.min_order_price && subtotal < provider.min_order_price ? (
          <div className="text-[12px] flex items-end py-1 text-gray-700 dark:text-gray-200">
            <p className="flex-grow mr-4">{t('minorderprice')}</p>
            <p>{provider.min_order_price.toFixed(2)}€</p>
          </div>
        ) : null}
      </div>

      <div className="">
        <Button
          className="w-full mt-4 flex items-center justify-center"
          disabled={
            step === Steps.MENU &&
            (!provider.store_acceptOrders ||
              menuItems.length <= 0 ||
              (provider.min_order_price && subtotal < provider.min_order_price))
          }
          variant={step === Steps.COMPLETE ? ButtonVariants.Secondary : ButtonVariants.Primary}
          onClick={step === Steps.MENU ? handleNext : handlePrevious}
        >
          {step === Steps.MENU ? t('nextstep') : t('edit')}
        </Button>
      </div>

      {step === Steps.COMPLETE && (
        <div className="pt-3 text-[10px]">
          {provider.payment_methods?.credit &&
          provider.creditcard_min_price &&
          subtotal < provider.creditcard_min_price &&
          !provider.store_menu_only ? (
            <div className="flex items-end text-gray-700 dark:text-gray-200">
              <p className="flex-grow mr-4">{t('creditcardminorderprice')}</p>
              <p>{provider.creditcard_min_price.toFixed(2)}€</p>
            </div>
          ) : null}

          {provider.deliveryAvailable && provider.free_delivery_from && subtotal < provider.free_delivery_from ? (
            <div className="flex items-end text-gray-700 dark:text-gray-200">
              <p className="flex-grow mr-4">{t('freedeliveryfrom')}</p>
              <p>{provider.free_delivery_from.toFixed(2)}€</p>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}
