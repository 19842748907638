import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Allergic, MenuItem, SupportedAllergics } from '../types/src';

export default function Allergics({ menuItem }: { menuItem: MenuItem }) {
  const { t } = useTranslation('provider');
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`mt-2 outline-none flex justify-between w-full px-4 py-4 text-sm font-medium text-left transition-all bg-surface-container-high text-on-surface hover:shadow dark:bg-opacity-50 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75 ${
              open ? 'rounded-t-lg rounded-b-none' : 'rounded-lg'
            } `}
          >
            <span>{t('allergens')}</span>
            <ChevronUpIcon className={`transition-all ${open ? '' : 'transform rotate-180'} w-5 h-5 text-primary `} />
          </Disclosure.Button>
          <Disclosure.Panel className="rounded-b-lg px-4 pt-4 pb-2 text-sm bg-surface-container-high text-on-surface grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-2">
            {(menuItem.allergics ?? []).map((allergic, index) => (
              <AllergenLine key={`allergen-${index}`} allergic={allergic} />
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

const AllergenLine = ({ allergic }: { allergic: Allergic }) => {
  const { locale } = useRouter();
  if (locale) {
    const allAllergics: Allergic[] = (SupportedAllergics as any)[locale];
    if (allAllergics) {
      const allergen = allAllergics.find((a) => a.code === allergic.code);
      if (allergen) {
        return (
          <div className="flex items-center">
            <img className="h-7 w-7 mr-4" src={`/allergic/${allergen.index}.png`} alt={allergen.code} />
            <span className="font-normal">{allergen.value}</span>
            <span className="ml-2">{allergen.letter}</span>
          </div>
        );
      }
    }
  }
  return <div></div>;
};
