import { Interval, Provider } from '../types/src';

export const todaysWeekday = new Date().getDay();

export interface TimeResponse {
  time: number;
  hours: number;
  minutes: number;
}

export function getCloseTime(intervals: Interval[]): TimeResponse {
  if (intervals && intervals.length) {
    const timeString = intervals[intervals.length - 1].to;
    const splitted = timeString.split(':');
    if (splitted.length == 2) {
      const date = new Date();
      date.setHours(Number(splitted[0]));
      date.setMinutes(Number(splitted[1]));
      const timeResponse: TimeResponse = {
        time: date.getTime(),
        hours: Number(splitted[0]),
        minutes: Number(splitted[1]),
      };
      return timeResponse;
    } else {
      return null;
    }
  }
}

export function deferTime(from: number, deferAmountHours: number, deferAmountMinutes: number): TimeResponse {
  const deferInMs = hoursAndMinutesInMs(deferAmountHours, deferAmountMinutes);
  const newTime = from + deferInMs;
  const date = new Date(newTime);

  return {
    time: newTime,
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
}

export function stringToTime(timeAsString: string): TimeResponse {
  const splitted = timeAsString.split(':');
  if (splitted.length == 2) {
    const date = new Date();
    date.setHours(Number(splitted[0]));
    date.setMinutes(Number(splitted[1]));

    const timeResponse: TimeResponse = {
      time: date.getTime(),
      hours: Number(splitted[0]),
      minutes: Number(splitted[1]),
    };
    return timeResponse;
  } else {
    return null;
  }
}

export function getDeliveryTimesBetween(from: TimeResponse, to: TimeResponse): TimeResponse[] {
  const availableDeliveryTimes: TimeResponse[] = [];
  let firstLoop: boolean = true;
  for (let i = from.hours; i <= to.hours; i++) {
    if (firstLoop) {
      for (let min = from.minutes ? Math.ceil(from.minutes / 10) * 10 : 0; min < 60; min = min + 10) {
        const date = new Date();
        date.setHours(from.hours);
        date.setMinutes(min);
        const tR: TimeResponse = {
          hours: from.hours,
          minutes: min,
          time: date.getTime(),
        };
        availableDeliveryTimes.push(tR);
      }
      firstLoop = false;
    } else if (i == to.hours) {
      for (let min = 0; min < to.minutes; min = min + 10) {
        const date = new Date();
        date.setHours(i);
        date.setMinutes(min);
        const tR: TimeResponse = {
          hours: i,
          minutes: min,
          time: date.getTime(),
        };

        availableDeliveryTimes.push(tR);
      }
    } else {
      for (let min = 0; min < 60; min = min + 10) {
        const date = new Date();
        date.setHours(i);
        date.setMinutes(min);
        const tR: TimeResponse = {
          hours: i,
          minutes: min,
          time: date.getTime(),
        };

        availableDeliveryTimes.push(tR);
      }
    }
  }
  return availableDeliveryTimes;
}

function hoursAndMinutesInMs(hours: number, minutes: number): number {
  const hoursMs = hours * 60 * 60 * 1000;
  const minutesMs = minutes * 60 * 1000;

  return hoursMs + minutesMs;
}

export function isInInterval(now: Date, from: string, to: string): boolean {
  const splitFrom = from.split(':');
  const startTime = new Date();
  const starthours = parseInt(splitFrom[0], 10);
  startTime.setHours(starthours);
  const startminutes = parseInt(splitFrom[1], 10);
  startTime.setMinutes(startminutes);

  const splitTo = to.split(':');
  const endTime = new Date();
  const endhours = parseInt(splitTo[0], 10);
  endTime.setHours(endhours);
  const endminutes = parseInt(splitTo[1], 10);
  endTime.setMinutes(endminutes);
  const between = now.getTime() >= startTime.getTime() && now.getTime() < endTime.getTime();
  return between;
}

export function isShopOpen(provider: Provider): boolean {
  const now = new Date();

  if (provider && provider.businessHours && provider.businessHours.active) {
    const day = now.getDay();
    let dayOfWeek;
    if (day === 0) {
      dayOfWeek = 7;
    } else {
      dayOfWeek = day;
    }

    if (provider.businessHours && dayOfWeek && provider.businessHours[dayOfWeek]) {
      return (
        provider.businessHours[dayOfWeek].findIndex((interval) => isInInterval(now, interval.from, interval.to)) > -1 ||
        provider.businessHours[dayOfWeek]?.length === 0
      );
    }
  } else {
    return true;
  }
}
