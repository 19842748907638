import { RadioGroup } from '@headlessui/react';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { getCategoryAvatarFromCode, getCategoryFromCode } from '../lib/helper';
import { ComboConfig } from '../types/src';
export default function ComboSelection({
  selection,
  config,
  changedSelection,
}: {
  selection: number;
  config: ComboConfig[];
  changedSelection: (index) => void;
}) {
  const { t } = useTranslation('provider');
  const { locale } = useRouter();

  return (
    <div className="w-full">
      <p className="text-center mb-4">{t('choose_food_description')}</p>
      <RadioGroup value={selection} onChange={changedSelection}>
        <div className="flex flex-row justify-center space-x-2 bg-primary-container p-2 w-fit mx-auto rounded-xl">
          {config.map((combo, index) => (
            <RadioGroup.Option
              key={`combo-${index}`}
              value={index}
              className={({ active, checked }) =>
                `${active ? '' : ''}
                  ${checked ? 'bg-primary text-on-primary' : 'bg-surface text-on-surface'}
                   font-semibold relative rounded-lg px-5 ${
                     combo.category_codes.length === 1 ? 'pr-12' : ''
                   } py-4 cursor-pointer flex focus:outline-none overflow-hidden`
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label as="div" className={`font-medium  ${checked ? '' : ''}`}>
                          <div className="flex flex-row items-center flex-wrap">
                            {combo.category_codes.length === 1 && (
                              <div className="absolute -bottom-4 -right-2">
                                <Image
                                  width={48}
                                  height={48}
                                  src={`/placeholders/${getCategoryAvatarFromCode(combo.category_codes[0], locale)}`}
                                  alt={'Combo pic'}
                                />
                              </div>
                            )}
                            <span>
                              {combo.category_codes.map((code) => getCategoryFromCode(code, locale)).join(' / ')}{' '}
                              {combo.max_selection && combo.max_selection > 0 && <strong>({combo.max_selection})</strong>}
                            </span>
                          </div>
                        </RadioGroup.Label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}
