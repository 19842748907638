import { create } from 'zustand';

import { Extra } from '../types/src';

const extrasStore = create((set) => ({
  extras: {},
  addCategoryCodeExtras: (category_code: string, extras: Extra[]) =>
    set((state) => {
      const previousExtras = state.extras;
      previousExtras[category_code] = extras;
      return {
        extras: previousExtras,
      };
    }),
  overrideExtras: (newExtras: { [category_code: string]: Extra[] }) =>
    set((state) => {
      return {
        extras: newExtras,
      };
    }),
  clear: () =>
    set(() => ({
      extras: {},
    })),
}));

export default extrasStore;
