import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import format from 'date-fns/format';
import { de, enUS, it } from 'date-fns/locale';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';

import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import orderStore, { Type } from '../lib/order';
import { BusinessHours, Provider } from '../types/src';
import { deferTime, getCloseTime, getDeliveryTimesBetween, stringToTime, TimeResponse, todaysWeekday } from '../utils/time';

export default function OrderTiming({ provider, type }: { provider: Provider; type: Type }) {
  const { t } = useTranslation('provider');

  const { locale } = useRouter();
  const [selected, setSelected] = useState<TimeResponse | null>();
  const setTargetDate = orderStore((state: any) => state.setTargetDate);

  function setSelectedTime(timeResp: TimeResponse | null): void {
    setSelected(timeResp);
    setTargetDate(timeResp?.time ? new Date(timeResp.time) : null);
  }

  function localizedDate(date: Date, localeCode: string, showDate: boolean = true) {
    switch (localeCode) {
      case 'de':
        return showDate
          ? `Heute, um ${format(date, 'HH:mm', { locale: de })} Uhr`
          : `${format(date, 'HH:mm', { locale: de })}`;
      case 'it':
        return showDate ? `Oggi, alle ${format(date, 'HH:mm', { locale: it })}` : `${format(date, 'HH:mm', { locale: it })}`;
      case 'en':
        return showDate
          ? `Today, at ${format(date, 'HH:mm', { locale: enUS })} o'clock`
          : `${format(date, 'HH:mm', { locale: enUS })}`;
      default:
        return showDate
          ? `Heute, um ${format(date, 'HH:mm', { locale: de })} Uhr`
          : `${format(date, 'HH:mm', { locale: de })}`;
    }
  }

  const closingTime: TimeResponse | undefined =
    provider.businessHours && provider.businessHours[todaysWeekday]
      ? getCloseTime(provider.businessHours[todaysWeekday])
      : undefined;

  const times = getAvailableDeliveryTimes(provider.businessHours, provider.delivery_time ?? '00:00');

  return (
    <div className="max-w-md">
      {closingTime ? (
        <p className="mb-2">
          {provider.name} {t('willbeclosingat')}{' '}
          <span className="text-red-500 dark:text-red-400 text-lg">
            {localizedDate(new Date(closingTime.time), locale, false)}
          </span>
          .
        </p>
      ) : null}
      {provider.delivery_time && type === Type.DELIVERY ? (
        <Trans
          i18nKey="provider:needstimefordelivery"
          values={{ time: provider.delivery_time }}
          components={[
            <p key="1" className="text-gray-700 dark:text-gray-200 mb-2" />,
            <span key="2" className="text-yellow-600 dark:text-yellow-300 text-lg"></span>,
          ]}
        />
      ) : null}

      <Listbox value={selected} onChange={setSelectedTime}>
        <div className="relative mt-1">
          <ListboxButton className="relative w-full py-2 pl-3 pr-10 text-left rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2  bg-surface-container text-on-surface sm:text-sm">
            <span className="block truncate">
              {selected ? `${localizedDate(new Date(selected.time), locale)}` : t('as_soon_as_possible')}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon className="w-5 h-5" aria-hidden="true" />
            </span>
          </ListboxButton>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <ListboxOptions className="absolute w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg max-h-60 focus:outline-none sm:text-sm bg-surface-container text-on-surface">
              {[null, ...times].map((time, personIdx) => (
                <ListboxOption
                  key={personIdx}
                  className={({ selected }) =>
                    `${selected ? 'bg-primary text-on-primary' : ''}
                          cursor-default select-none relative py-2 pl-10 pr-4 hover:bg-primary hover:text-on-primary`
                  }
                  value={time}
                >
                  {({ selected }) => (
                    <>
                      <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>
                        {time ? localizedDate(new Date(time.time), locale, false) : t('as_soon_as_possible')}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className={`w-5 h-5 ${selected ? 'text-primary' : ''}`} aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

function getAvailableDeliveryTimes(businessHours: BusinessHours, deliveryTime: string): TimeResponse[] {
  if (businessHours && deliveryTime) {
    const closingTime: TimeResponse | null = getCloseTime(businessHours[todaysWeekday]);

    const deliveryDefer: TimeResponse = stringToTime(deliveryTime);
    const deliveryDeferHours = deliveryDefer.hours;
    const deliveryDeferMinutes = deliveryDefer.minutes;

    const offsetMinutes = 10;
    const nextPossibleTime = deferTime(new Date().getTime(), deliveryDeferHours, deliveryDeferMinutes + offsetMinutes);

    if (nextPossibleTime && closingTime) {
      const times = getDeliveryTimesBetween(nextPossibleTime, closingTime);
      return times;
    } else if (nextPossibleTime) {
      const closingTime: TimeResponse = stringToTime('23:59');
      const times = getDeliveryTimesBetween(nextPossibleTime, closingTime);
      return times;
    } else {
      return [];
    }
  } else {
    return [];
  }
}
