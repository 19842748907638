import { CheckCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { ChangeEvent, DetailedHTMLProps, forwardRef, InputHTMLAttributes, ReactNode } from 'react';

export type TextInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: string;
  placeholder?: string;
  hint?: string;
  defaultValue?: string;
  error?: string | ReactNode;
  success?: string | ReactNode;
  disabled?: boolean;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (value: ChangeEvent<HTMLInputElement>) => void;
};

const styles = (error: boolean, disabled: boolean) =>
  classNames({
    'transition-all bg-white dark:bg-black text-on-background bg-opacity-50 dark:bg-opacity-20 w-full text-base placeholder-gray-500 outline-none border border-gray-300 dark:border-gray-600 rounded-md py-2 px-2 focus:border-primary focus:outline-none active:border-primary':
      true,
    'border border-input-light-border dark:border-input-dark-border hover:border-black hover:dark:border-white': true,
    'focus:outline-none focus:ring-0 text-base text-black dark:text-white placeholder:italic placeholder-gray-700 dark:placeholder-gray-700':
      true,
    'border border-error dark:border-error focus:border-error': error,
    'pointer-events-none text-gray-500 dark:text-gray-800 border border-gray-700 dark:border-gray-900 border-opacity-30 dark:border-opacity-30 hover:border-gray-700 hover:dark:border-gray-900 hover:border-opacity-30 hover:dark:border-opacity-30 cursor-default':
      disabled,
  });

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { label, placeholder, defaultValue, required = false, error, hint, disabled, success, onChange, onBlur, ...props },
    ref,
  ) => {
    return (
      <label className="flex flex-col text-[12px] text-on-surface">
        {(label || error) && (
          <span
            className={`block whitespace-nowrap overflow-hidden text-ellipsis opacity-60 mb-1 leading-14.5px ${error ? 'text-warn-light-500 dark:text-warn-dark-500' : ''}`}
          >
            {label} {required && '*'}
          </span>
        )}
        <input
          ref={ref}
          className={styles(!!error, !!disabled)}
          type="text"
          defaultValue={defaultValue}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => onChange && onChange(e)}
          onBlur={(e) => onBlur && onBlur(e)}
          {...props}
        />

        {(error || hint) && (
          <div className={`mt-1 flex flex-row text-12px leading-14.5px h-14.5px ${error ? 'text-error' : ''} `}>
            {error ? (
              <i className="text-xs mr-1 las la-exclamation-circle"></i>
            ) : hint ? (
              <i className="text-xs mr-1 las la-info-circle"></i>
            ) : (
              <></>
            )}
            {error ? error : hint ? hint : <span></span>}
          </div>
        )}

        {success && (
          <div className="mt-1 flex text-md flex-row items-center text-green-500">
            <CheckCircleIcon className="h-4 w-4" />
            <span className="ml-1">{success}</span>
          </div>
        )}
      </label>
    );
  },
);
