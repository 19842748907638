import { getCategoryFromCode } from '../lib/helper';
import { MenuItem } from '../types/src';
type Props = {
  menuItems: MenuItem[];
  locale: string;
};
export default function CategoryList({ menuItems, locale }: Props) {
  const codes = menuItems.map((menuitem) => menuitem.category_code);
  const categorySet = new Set(codes);

  return (
    <div id="categories" className="flex flex-wrap items-center px-4 py-4">
      {Array.from(categorySet).map((categoryCode: string, i: number) => (
        <a
          key={i}
          href={`#${categoryCode}`}
          className="transition-all rounded-full bg-secondary-container hover:bg-tertiary-container text-on-secondary-container hover:text-on-tertiary-container relative group transition-all h-8 flex items-center justify-center px-4 text-sm mr-2 mb-2 font-semibold"
        >
          {getCategoryFromCode(categoryCode, locale)}
        </a>
      ))}
    </div>
  );
}
