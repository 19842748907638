import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

import { ChevronUpIcon } from '@heroicons/react/24/outline';
import extrasStore from '../lib/extras';
import { Extra } from '../types/src';

type Props = {
  categoryCode: string;
  locale: string;
  emitExtras: (extras: Extra[]) => void;
};

export default function Extras({ categoryCode, locale, emitExtras }: Props) {
  const [extrasToAdd, setExtrasToAdd] = useState({});

  const extras = extrasStore((state) => (state as any).extras);

  const [orderedExtras, setOrderedExtras] = useState({});

  function decrementCount(extraToEdit: Extra) {
    let extraToDecrement = extrasToAdd[extraToEdit.uid];
    if (extraToDecrement) {
      const newCount = extraToDecrement.count > 1 ? extraToDecrement.count - 1 : 0;
      if (newCount > 0) {
        extraToDecrement.count = newCount;
      } else {
        delete extrasToAdd[extraToEdit.uid];
      }
      setAndEmitExtras(extrasToAdd);
    } else {
      delete extrasToAdd[extraToEdit.uid];
      setAndEmitExtras(extrasToAdd);
    }
  }

  function incrementCount(extraToEdit: Extra) {
    let extraToIncrement = extrasToAdd[extraToEdit.uid];
    if (extraToIncrement) {
      const newCount = extraToIncrement.count + 1;
      extraToIncrement.count = newCount;
      setAndEmitExtras(extrasToAdd);
    } else {
      extrasToAdd[extraToEdit.uid] = { count: 1, ...extraToEdit };
      setAndEmitExtras(extrasToAdd);
    }
  }

  function toggleExtra(extraToEdit: Extra, group: string, $event: any) {
    if (group) {
      // radio
      let toReplaceKey = Object.keys(extrasToAdd).find((key) => extrasToAdd[key].groups[locale] === group);
      if (toReplaceKey) {
        delete extrasToAdd[toReplaceKey];
        if ($event.target.checked) {
          setAndEmitExtras({ [extraToEdit.uid]: extraToEdit, ...extrasToAdd });
        } else {
          setAndEmitExtras(extrasToAdd);
        }
      } else {
        setAndEmitExtras({ [extraToEdit.uid]: extraToEdit, ...extrasToAdd });
      }
    } else {
      // checkbox
      let oldExtra = extrasToAdd[extraToEdit.uid];
      if (oldExtra) {
        delete extrasToAdd[extraToEdit.uid];
        setAndEmitExtras(extrasToAdd);
      } else {
        extrasToAdd[extraToEdit.uid] = extraToEdit; // { count: 1, ...extraToEdit };
        setAndEmitExtras(extrasToAdd);
      }
    }
  }

  function setAndEmitExtras(extras: { [uid: string]: Extra }): void {
    setExtrasToAdd(Object.assign({}, extras));

    const array: Extra[] = Object.values(extras);
    emitExtras(array);
  }

  const groupBy = (locale) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj.groups[locale];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
  const groupByGroup = groupBy(locale);

  useEffect(() => {
    const orderedExtras = groupByGroup(extras[categoryCode]);
    setOrderedExtras(orderedExtras);
  }, [extras]);

  const { t } = useTranslation('order');

  const iconButton =
    'transition-all outline-none flex items-center justify-center h-8 w-8 rounded-t-full hover:bg-opacity-10 dark:hover:bg-opacity-10 hover:bg-gray-800 dark:hover:bg-gray-400 focus:outline-none';

  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`mt-2 outline-none flex justify-between w-full px-4 py-4 text-sm font-medium text-left transition-all bg-surface-container-high text-on-surface hover:shadow dark:bg-opacity-50 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75 ${
              open ? 'rounded-t-lg rounded-b-none' : 'rounded-lg'
            } `}
          >
            <span>{t('provider:change_extras')}</span>
            <ChevronUpIcon className={`transition-all ${open ? '' : 'transform rotate-180'} w-5 h-5 text-primary `} />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm bg-surface-container-high text-on-surface rounded-b-lg">
            <div className="flex-col mb-2">
              {/* <h3 className="text-xl mb-2">Extras</h3> */}
              <div
                className={`pr-24 flex flex-row flex-wrap gap-2 xl:grid xl:grid-cols-${Object.keys(orderedExtras).length}`}
              >
                {orderedExtras && Object.keys(orderedExtras).length
                  ? Object.keys(orderedExtras).map((group) => {
                      return (
                        <div key={group}>
                          {group ? (
                            <span className="text-sm text-gray-500 dark:text-gray-400 block">{group}</span>
                          ) : (
                            <span className="text-sm text-gray-500 dark:text-gray-400 block">
                              {t('extras.common_extra_group')}
                            </span>
                          )}
                          {orderedExtras[group].map((extra, i) =>
                            extra.is_multiple ? (
                              <div className="flex items-center my-1" key={`${group}${i}`}>
                                <div className={`${group === '' ? 'w-22' : ''} mr-2 flex justify-end items-center`}>
                                  <button className={`${iconButton}`} onClick={() => decrementCount(extra)}>
                                    <MinusIcon className="w-5 h-5 text-primary" />
                                  </button>
                                  <span className="text-lg mx-2">{extrasToAdd[extra.uid]?.count ?? 0}</span>
                                  <button className={`${iconButton}`} onClick={() => incrementCount(extra)}>
                                    <PlusIcon className="w-5 h-5 text-primary" />
                                  </button>
                                </div>
                                <span>{extra.names[locale]}</span>
                                <span className="px-4 text-base">
                                  {`${extra.price ? extra.price.toFixed(2) : (0).toFixed(2)}€`}
                                </span>
                              </div>
                            ) : (
                              <div key={`${group}${i}`}>
                                <label className="flex items-center my-1">
                                  <div className={`${group === '' ? '' : ''} mr-2 flex justify-end items-center`}>
                                    {group === '' ? (
                                      <input
                                        type="checkbox"
                                        name={extra.names[locale]}
                                        value={extra}
                                        checked={!!extrasToAdd[extra.uid]}
                                        onChange={(event) => toggleExtra(extra, group, event)}
                                        className={`bringmos-checkbox`}
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        name={group}
                                        value={extra}
                                        checked={!!extrasToAdd[extra.uid]}
                                        onChange={(event) => toggleExtra(extra, group, event)}
                                        className={`bringmos-checkbox`}
                                      />
                                    )}
                                  </div>
                                  <span className="">{extra.names && extra.names[locale] ? extra.names[locale] : null}</span>
                                  <span className="px-4 text-base">
                                    {`${extra.price ? extra.price.toFixed(2) : (0).toFixed(2)}€`}
                                  </span>
                                </label>
                              </div>
                            ),
                          )}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
