import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import extrasStore from '../lib/extras';
import { getCategoryAvatarFromCode, getCategoryFromCode } from '../lib/helper';
import ingredientsStore from '../lib/ingredients';
import orderStore from '../lib/order';
import { ExtraOrder, Ingredient, MenuItem, MenuItemOrder, Provider } from '../types/src';
import { jsonToTimestamp } from '../utils/date';
import { isItemLimited } from '../utils/menuItem';
import { Button } from './Button';
import Extras from './Extras';
import IngredientSelect, { IngredientReturnType } from './IngredientSelect';

const ReactTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false,
});

export enum ExpandedView {
  INGREDIENTS,
  PIZZA_CONFIGURATOR,
  ADDITIONAL_INFO,
}

type Props = {
  provider: Provider;
  customMenuItem: MenuItem;
  locale: string;
  toggle: any;
  expanded: boolean;
  disabled: boolean;
};

function CustomMenuItemComponent(props: Props) {
  const [count, setCount] = useState<number>(1);
  const [extras, setExtras] = useState<ExtraOrder[]>([]);
  const [ingredients, setIngredients] = useState<IngredientReturnType | null>();

  const loadedIngredients = ingredientsStore((state) => (state as any).ingredients);
  const loadedExtras = extrasStore((state) => (state as any).extras);

  useEffect(() => {
    setCount(1);
    setExtras([]);
    setIngredients(null);
  }, [props.expanded]);

  function decrementCount() {
    const newCount = count > 1 ? count - 1 : 1;
    setCount(newCount);
  }

  function incrementCount() {
    const newCount = count + 1;
    setCount(newCount);
  }

  function clickContainer($event: any) {
    if ($event.target.tagName !== 'BUTTON') {
      $event.preventDefault();
      props.toggle(props.customMenuItem.uid);
    }
  }

  function setExtrasToMenuItem(extras: ExtraOrder[]) {
    setExtras(extras);
  }

  function setIngredientsToMenuItem(ings: IngredientReturnType) {
    setIngredients(ings);
  }

  const { t } = useTranslation('provider');
  const addMenuItem = orderStore((state: any) => state.addMenuItem);

  const ingPrice =
    ingredients?.added_ingredients && Object.keys(ingredients.added_ingredients).length
      ? Object.keys(ingredients.added_ingredients)
          .map((id) => loadedIngredients.find((ing: Ingredient) => ing.uid === id)?.price ?? 0)
          .reduce((acc, price) => acc + price, 0)
      : 0;

  function addMenuItemToOrder() {
    // always equals to the first attribute
    const sumWithIngredients = Number(props.customMenuItem.price) + Number(ingPrice);
    let newItem: Partial<MenuItemOrder> = {
      ...props.customMenuItem,
      extraItems: extras,
      custom: true,
      initial_ingredients: props.customMenuItem.multi_ingredients
        ? props.customMenuItem.multi_ingredients
        : {
            de: '',
            it: '',
            en: '',
          },
      initialIngredientIds: props.customMenuItem.ingredientIds ? props.customMenuItem.ingredientIds : [],
      price: sumWithIngredients,
      date:
        props.customMenuItem.date && typeof props.customMenuItem.date == 'object'
          ? jsonToTimestamp(props.customMenuItem.date)
          : props.customMenuItem.date,
      ...ingredients,
    };

    addMenuItem(newItem, count, props.locale);
  }

  const itemLimited = isItemLimited(props.customMenuItem);

  const iconButton =
    'transition-all outline-none flex items-center justify-center h-8 w-8 rounded-full text-primary dark:text-primary hover:bg-opacity-10 dark:hover:bg-opacity-10 hover:bg-gray-800 dark:hover:bg-gray-400 focus:outline-none disabled:text-gray-300 dark:disabled:text-gray-500 disabled:bg-transparent dark:disabled:bg-transparent disabled:cursor-default dark:disabled:cursor-default';
  const selectedIngredients =
    loadedIngredients && loadedIngredients.length
      ? loadedIngredients.filter((ings: Ingredient) =>
          props.customMenuItem.ingredientIds ? props.customMenuItem.ingredientIds.includes(ings.uid ?? '') : false,
        )
      : [];

  return (
    <div
      className={`relative overflow-hidden bg-surface-container min-h-29 rounded-lg filter grayscale-50 flex transition-all ${
        props.disabled || itemLimited ? 'filter grayscale-50' : 'hover:shadow-md hover:border-color-outline'
      } flex-col ${props.expanded ? 'lg:col-span-2 shadow-md ' : ''}`}
    >
      <div
        className={`z-10 flex items-center px-4 py-3 ${props.disabled || itemLimited ? 'cursor-default' : 'cursor-pointer'}`}
        onClick={(event) =>
          props.disabled || itemLimited || !props.provider.store_acceptOrders ? null : clickContainer(event)
        }
      >
        <div>
          <p className="font-semibold">{props.customMenuItem.names[props.locale]}</p>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {getCategoryFromCode(props.customMenuItem.category_code, props.locale)}
          </p>
        </div>
        <span className="flex-1"></span>
        <span className="mr-2">
          {props.expanded
            ? (
                count * props.customMenuItem.price +
                ingPrice +
                (extras && extras.length
                  ? extras
                      .map((e) => ((e.is_multiple && e.count) > 1 ? e.count * e.price : e.price))
                      .reduce((acc, price) => acc + price, 0)
                  : 0)
              ).toFixed(2)
            : `${t('provider:from')} ${props.customMenuItem.price.toFixed(2)}`}
          €
        </span>
        {props.expanded ? (
          <>
            {itemLimited && !props.disabled && (
              <ExclamationCircleIcon className="h-5 w-5 mx-2 text-error focus:outline-none"></ExclamationCircleIcon>
            )}

            <button
              disabled={props.disabled || itemLimited || !props.provider.store_acceptOrders}
              className={`${iconButton}`}
              onClick={(event) => {
                props.toggle(props.customMenuItem.uid);
              }}
            >
              <ChevronUpIcon className={`h-5 w-5`}></ChevronUpIcon>
            </button>
            <div className="flex items-center ml-2">
              <button
                disabled={props.disabled || itemLimited || !props.provider.store_acceptOrders}
                className={`${iconButton}`}
                onClick={decrementCount}
              >
                <MinusIcon className={`pointer-events-none h-5 w-5`} />
              </button>
              <span className="text-lg mx-2">{count}</span>
              <button
                disabled={props.disabled || itemLimited || !props.provider.store_acceptOrders}
                className={`${iconButton}`}
                onClick={incrementCount}
              >
                <PlusIcon className={`pointer-events-none h-5 w-5`} />
              </button>
            </div>
          </>
        ) : (
          <>
            {itemLimited && (
              <ExclamationCircleIcon
                data-tip={`${t('order:itemlimited')}`}
                className="h-5 w-5 mx-2 text-error focus:outline-none"
              ></ExclamationCircleIcon>
            )}

            <button
              disabled={props.disabled || itemLimited || !props.provider.store_acceptOrders}
              className={`${iconButton}`}
              onClick={(event) => {
                props.toggle(props.customMenuItem.uid);
              }}
            >
              <ChevronDownIcon className={`h-5 w-5`}></ChevronDownIcon>
            </button>

            <ReactTooltip effect="solid" />
          </>
        )}
      </div>
      {props.customMenuItem.imageUrl ? (
        <img
          loading="lazy"
          className={`transition-all duration-100 ${
            props.expanded ? 'h-0 w-0' : 'h-28 w-28'
          } absolute -bottom-12 -right-4 object-center ${
            props.disabled || itemLimited || !props.provider.store_acceptOrders ? 'filter grayscale grayscale-100' : ''
          }`}
          src={props.customMenuItem.imageUrl}
          alt={props.customMenuItem.names[props.locale] || 'Item pic'}
        />
      ) : (
        <img
          loading="lazy"
          className={`transition-all duration-100 ${
            props.expanded ? 'h-0 w-0' : 'h-28 w-28'
          } absolute -bottom-12 -right-4 object-center ${
            props.disabled || itemLimited || !props.provider.store_acceptOrders ? 'filter grayscale grayscale-100' : ''
          }`}
          src={`/placeholders/${getCategoryAvatarFromCode(props.customMenuItem.category_code, props.locale)}`}
          alt={props.customMenuItem.names[props.locale] || 'Item pic'}
        />
      )}

      <div
        onClick={(event) =>
          props.disabled || itemLimited || !props.provider.store_acceptOrders ? null : clickContainer(event)
        }
        className={`px-4 pr-24 mb-2 ${props.disabled || itemLimited || !props.provider.store_acceptOrders ? 'cursor-default' : 'cursor-pointer'}`}
      >
        {props.customMenuItem.descriptions && props.customMenuItem.descriptions[props.locale] ? (
          <span className="text-sm text-gray-500 dark:text-gray-400 italic my-2">
            {props.customMenuItem.descriptions[props.locale]}
          </span>
        ) : (
          <span>&nbsp;</span>
        )}
        {props.customMenuItem?.multi_tags && props.customMenuItem.multi_tags[props.locale] ? (
          <div className="flex flex-wrap my-2">
            {props.customMenuItem?.multi_tags[props.locale].map((tag) => (
              <span key={tag} className="text-grey mr-4">
                #{tag === 'custom' ? t('provider:custom_pizza') : tag}
              </span>
            ))}
          </div>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>

      {props.expanded ? (
        <div className="px-4 pt-4 border-t border-outline-variant pb-4 flex flex-col">
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="outline-none flex justify-between w-full px-4 py-4 text-sm font-medium text-left transition-all bg-surface-container-high text-on-surface hover:shadow dark:bg-opacity-50 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-bringmos focus-visible:ring-opacity-75">
                  <span>{t('change_ingredients')}</span>
                  <ChevronUpIcon className={`transition-all ${open ? '' : 'transform rotate-180'} w-5 h-5 text-primary `} />
                </Disclosure.Button>
                <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                  <IngredientSelect
                    hidePrice={false}
                    hidePreview={false}
                    emitIngredients={setIngredientsToMenuItem}
                    selectedIngredients={selectedIngredients}
                    locale={props.locale}
                  />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          {loadedExtras &&
          loadedExtras[props.customMenuItem.category_code] &&
          loadedExtras[props.customMenuItem.category_code].length ? (
            <Extras
              categoryCode={props.customMenuItem.category_code}
              locale={props.locale}
              emitExtras={setExtrasToMenuItem}
            ></Extras>
          ) : null}

          <Button
            disabled={props.disabled || itemLimited}
            onClick={() => addMenuItemToOrder()}
            className="self-end z-10 mt-4 flex items-center"
          >
            <i className="mr-2 las la-cart-plus text-3xl"></i>
            <span>{t('addtocart')}</span>
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default CustomMenuItemComponent;
