import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Control, Controller } from 'react-hook-form';
import orderStore from '../lib/order';

import { CheckIcon } from '@heroicons/react/24/outline';
import { Provider, User } from '../types/src';

type Props = {
  provider: Provider;
  control: Control<User>;
};

const labelClasses = 'flex flex-col text-[12px] text-on-surface opacity-60 mb-1 leading-14.5px';
const inputClasses =
  'text-left transition-all bg-white dark:bg-black bg-opacity-50 dark:bg-opacity-20 w-full text-base placeholder-gray-500 outline-none border border-gray-300 dark:border-gray-600 rounded-md py-2 px-2 focus:border-primary focus:outline-none active:border-primary border border-input-light-border dark:border-input-dark-border hover:border-black hover:dark:border-white';
const inputErrorClasses =
  'w-full flex justify-start transition-all bg-white dark:bg-black bg-opacity-50 dark:bg-opacity-20 block text-base placeholder-gray-500 outline-none border border-red-500 dark:border-red-500 rounded-md py-2 px-2 focus:ring focus:ring ring-bringmos-400 ring-opacity-50 dark:ring-opacity-70 focus:border-red-500 focus:outline-none active:border-red-500';

const DeliveryTown = ({ provider, control }: Props) => {
  const { t } = useTranslation('profile');
  const { locale } = useRouter();

  const subtotal = orderStore((state) => (state as any).subtotal);

  const checkIfIsLocation = (value): boolean => {
    const val = provider.delivery_locations
      ? provider.delivery_locations
          .filter((l) => l.names && l.names[locale ?? 'de'])
          .map((location) => location && location.names && location.names[locale ?? 'de'])
          .findIndex((location) => location == value) > -1
      : false;
    return val;
  };

  return (
    <div className="relative">
      <label className={labelClasses} htmlFor="clienttown">
        {t('form.town')}
      </label>
      <Controller
        name="client_town"
        control={control}
        defaultValue=""
        rules={{
          required: true,
          validate: checkIfIsLocation,
        }}
        render={({ field: { onChange, value }, fieldState: { isTouched }, formState }) => {
          const isValid = checkIfIsLocation(value);

          return (
            <Listbox value={value} onChange={onChange}>
              <ListboxButton className={!isValid ? inputErrorClasses : `${inputClasses}`}>
                {value ? `${value}` : <span className="text-gray-500 italic">{t('selecttown')}</span>}
              </ListboxButton>
              <ListboxOptions className="z-10 absolute t-14 left-0 rounded-md shadow mt-2 w-60 overflow-x-hidden overflow-y-auto outline-none max-h-[300px] overflow-scroll bg-surface text-on-surface cursor-pointer text-sm">
                {(provider.delivery_locations ? provider.delivery_locations : [])
                  .filter((l) => l.names && l.names[locale ?? 'de'])
                  .map((location, l) => (
                    <ListboxOption
                      key={location && location.names ? location.names[locale ?? 'de'] : `location-${l}`}
                      value={location.names ? location.names[locale ?? 'de'] : ''}
                    >
                      {({ active, selected }) => (
                        <div
                          className={`py-1 px-2 overflow-hidden flex items-center ${
                            active ? 'bg-primary text-on-primary' : ''
                          }`}
                        >
                          {selected && <CheckIcon className="flex-shrink-0 block h-6 w-6 mr-2 text-primary" />}
                          {!selected && <CheckIcon className="flex-shrink-0 block invisible h-6 w-6 mr-2" />}
                          <span className="mr-4 overflow-hidden">
                            {location.names ? location.names[locale ?? 'de'] : ''}
                          </span>
                          <span className="flex-1"></span>
                          <span className="overflow-hidden">
                            {provider.free_delivery_from && subtotal >= provider.free_delivery_from ? (
                              <span className="text-xs">{t('provider:free')}</span>
                            ) : location.price ? (
                              `${location.price.toFixed(2)}€`
                            ) : (
                              `${(0).toFixed(2)}€`
                            )}
                          </span>
                        </div>
                      )}
                    </ListboxOption>
                  ))}
              </ListboxOptions>
              {!isValid ? <p className="text-xs text-error mt-1">{t('selectvalidtown')}</p> : null}
            </Listbox>
          );
        }}
      ></Controller>
    </div>
  );
};

export default DeliveryTown;
