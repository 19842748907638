import { create } from 'zustand';

import { Ingredient } from '../types/src';

const ingredientsStore = create((set) => ({
  ingredients: [],
  overrideIngredients: (newIngredients: Ingredient[]) =>
    set((state) => {
      return {
        ingredients: newIngredients,
      };
    }),
  clear: () =>
    set(() => ({
      ingredients: [],
    })),
}));

export default ingredientsStore;
