import { Radio, RadioGroup } from '@headlessui/react';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';

import { CheckCircleIcon } from '@heroicons/react/24/outline';
import orderStore, { Payment, Type } from '../lib/order';
import { Provider } from '../types/src';

export default function OrderPayment({
  provider,
  payment,
  setPayment,
  type,
}: {
  provider: Provider;
  payment: Payment;
  setPayment: (payment: Payment) => void;
  type: Type;
}) {
  const subtotal = orderStore((state) => (state as any).subtotal);

  const CASH = {
    name: 'paymenttypes.cash.title',
    description: 'paymenttypes.cash.description',
    type: Payment.CASH,
  };
  const BANCOMAT = {
    name: 'paymenttypes.bancomat.title',
    description: 'paymenttypes.bancomat.description',
    type: Payment.BANCOMAT,
  };
  const CREDIT = {
    name: 'paymenttypes.credit.title',
    description: 'paymenttypes.credit.description',
    type: Payment.CREDIT,
    disabled: provider.creditcard_min_price && subtotal < provider.creditcard_min_price,
  };
  const MONNI = {
    name: 'provider:paymenttypes.monni.title',
    description: 'provider:paymenttypes.monni.description',
    type: Payment.MONNI,
  };

  const plans = [];

  if (provider.payment_methods?.cash) {
    plans.push(CASH);
  }
  if (provider.payment_methods?.bancomat) {
    plans.push(BANCOMAT);
  }
  if (provider.payment_methods?.credit) {
    plans.push(CREDIT);
  }
  if (provider.payment_methods?.monni_card) {
    plans.push(MONNI);
  }

  useEffect(() => {
    if (plans.length) {
      setPayment(plans[0].type);
    }
  }, []);

  const { t } = useTranslation('provider');

  return (
    <div className="w-full py-4">
      <div className="w-full max-w-md">
        <RadioGroup value={payment} onChange={setPayment}>
          <div className="space-y-2">
            {type === Type.DELIVERY ? (
              plans.map((plan) => (
                <Radio
                  key={plan.name}
                  value={plan.type}
                  disabled={plan.disabled}
                  className={({ checked, disabled }) =>
                    `${disabled ? 'cursor-not-allowed' : ''}
                  ${
                    checked
                      ? 'bg-primary text-on-primary'
                      : disabled
                        ? 'bg-gray-200 dark:bg-gray-700 bg-opacity-50 dark:bg-opacity-20'
                        : 'bg-surface-container-high text-on-surface'
                  }
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
                  }
                >
                  {({ disabled, checked }) => (
                    <>
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <p className={`font-medium  ${disabled ? 'text-gray-900 dark:text-gray-300' : ''}`}>
                              {t(plan.name)}
                            </p>
                            <span className={`inline ${disabled ? 'text-primary dark:text-primary' : ''}`}>
                              {disabled ? (
                                <span>
                                  {t('creditcard_minprice_not_reached')} {`(${provider.creditcard_min_price.toFixed(2)}€)`}
                                </span>
                              ) : (
                                <span>{t(plan.description)}</span>
                              )}
                            </span>
                          </div>
                        </div>
                        {checked && (
                          <div className="flex-shrink-0">
                            <CheckCircleIcon className="w-6 h-6" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Radio>
              ))
            ) : (
              <p>{t('localpayment')}</p>
            )}

            {plans.length === 0 && type === Type.DELIVERY ? <p className="text-primary">{t('nopaymentmethods')}</p> : null}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}
