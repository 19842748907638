import Head from 'next/head';
import { jsonLdScriptProps } from 'react-schemaorg';
import { Menu, Restaurant } from 'schema-dts';

import { MenuItem } from '../../types/src';

type Props = {
  name: string;
  phone: string;
  url: string;
  address: string;
  zip: number;
  town: string;
  ratingValue: number;
  ratingCount: number;
  image?: string;
  description?: string;
  menuItems: MenuItem[];
  locale: string;
};

const base_url = 'https://bringmos.com/';

const groupBy = (array: MenuItem[]) =>
  array.reduce((objectsByKeyValue, obj: MenuItem) => {
    const value = obj.category_code;
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export default function JsonLdMeta({
  name,
  phone,
  url,
  address,
  zip,
  town,
  ratingValue,
  ratingCount,
  image,
  description,
  menuItems,
  locale,
}: Props) {
  const groupedMenuitems = groupBy(menuItems);

  const jsonLDMenu: Menu = {
    '@type': 'Menu',
    name: 'Menu',
    description: 'description',
    hasMenuSection: Object.keys(groupedMenuitems).map((code) => {
      return {
        '@type': 'MenuSection',
        name: code,
        // description: "Dinner dishes",
        // image: "https://pronto-ny.com/dinner_dishes.jpg",
        hasMenuItem: groupedMenuitems[code].map((item: MenuItem) => {
          return {
            '@type': 'MenuItem',
            name: item.names[locale],
            description: item.descriptions[locale],
            image: item.imageUrl,
            // offer: {
            //     "@type": "Offer",
            // }
          };
        }),
      };
    }),
  };

  return (
    <Head>
      <script
        {...jsonLdScriptProps<Restaurant>({
          '@context': 'https://schema.org',
          '@type': 'Restaurant',
          mainEntityOfPage: base_url + url,
          url: `https://bringmos.com/${url}`,
          name: name,
          telephone: phone,
          image: image,
          description: description,
          address: {
            '@type': 'PostalAddress',
            addressLocality: town,
            streetAddress: address,
            postalCode: `${zip}`,
          },
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: ratingValue,
            reviewCount: ratingCount,
          },
          hasMenu: jsonLDMenu,
          // openingHours: [
          //     "Mo-Su 13:00-19:00"
          // ],
        })}
      />
    </Head>
  );
}
